import { useState, FC } from 'react';
import { createContext } from 'react';
import { Patient } from 'src/interfaces/interfaces';

type PatientProviderType = {
    currentPatientId: number;
    setCurrentPatientId: (val: number) => void;

    justAddedPatient: any;
    setJustAddedPatient: (val: any) => void;

    currentPatientProgram: any[];
    setCurrentPatientProgram: (val: any[]) => void;

    currentPatientAntecedant: any[];
    setCurrentPatientAntecedant: (val: any[]) => void;

    currentPatientConstant: any[];
    setCurrentPatientConstant: (val: any[]) => void;

    currentPatientGoal: any[];
    setCurrentPatientGoal: (val: any[]) => void;

    axis: any[];
    setAxis: (val: any[]) => void;

    axisCompteur: number;
    setAxisCompteur: (val: number) => void;

    updateList: boolean;
    setUpdateList: (val: any) => void;

    patientDefaultStatus: any;
    setPatientDefaultStatus: (val: boolean) => void;

    activeLoadingIndicator: any;
    setActiveLoadingIndicator: (val: any) => void;

    currentLocationPathname: string;
    setCurrentLocationPathname: (val: string) => void;

    observationId: number;
    setObservationId: (val: number) => void;

    observatNotifData: any[];
    setObservatNotifData: (val: any[]) => void;

    conclusionList: any[];
    setConclusionList: (val: any[]) => void;

    read: any;
    setRead: (val: boolean) => void;

    updateListProfessionnals: boolean;
    setUpdateListProfessionnals: (val: any) => void;

    fetchingPatientData: boolean;
    setFetchingPatientData: (val: any) => void;

    selectedPatient: Patient | null;
    setSelectedPatient: (val: Patient | null | any) => void;
};

export const PatientContext = createContext<PatientProviderType>({
    currentPatientId: 0,
    setCurrentPatientId: () => {},

    justAddedPatient: null,
    setJustAddedPatient: () => {},

    currentPatientProgram: [],
    setCurrentPatientProgram: () => {},

    currentPatientAntecedant: [],
    setCurrentPatientAntecedant: () => {},

    currentPatientConstant: [],
    setCurrentPatientConstant: () => {},

    currentPatientGoal: [],
    setCurrentPatientGoal: () => {},

    axis: [],
    setAxis: () => {},

    axisCompteur: 0,
    setAxisCompteur: () => {},

    updateList: false,
    setUpdateList: () => {},

    patientDefaultStatus: [],
    setPatientDefaultStatus: () => {},

    currentLocationPathname: '',
    setCurrentLocationPathname: () => {},

    observationId: 0,
    setObservationId: () => {},

    observatNotifData: [],
    setObservatNotifData: () => {},

    conclusionList: [],
    setConclusionList: () => {},

    read: [],
    setRead: () => {},

    updateListProfessionnals: false,
    setUpdateListProfessionnals: () => {},

    fetchingPatientData: false,
    setFetchingPatientData: () => {},

    selectedPatient: null,
    setSelectedPatient: () => {},

    activeLoadingIndicator: false,
    setActiveLoadingIndicator: () => {},
});

const PatientProvider: FC = (props) => {
    const { children } = props;
    const [currentPatientId, setCurrentPatientId] = useState<number>(0);
    const [justAddedPatient, setJustAddedPatient] = useState<any>(null);
    const [currentPatientProgram, setCurrentPatientProgram] = useState<any[]>([]);
    const [currentPatientAntecedant, setCurrentPatientAntecedant] = useState<any[]>([]);
    const [currentPatientConstant, setCurrentPatientConstant] = useState<any[]>([]);
    const [currentPatientGoal, setCurrentPatientGoal] = useState<any[]>([]);
    const [axis, setAxis] = useState<any[]>([]);
    const [axisCompteur, setAxisCompteur] = useState<number>(0);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [patientDefaultStatus, setPatientDefaultStatus] = useState<any>();
    const [currentLocationPathname, setCurrentLocationPathname] = useState<any>();
    const [observationId, setObservationId] = useState<any>();
    const [observatNotifData, setObservatNotifData] = useState<any[]>([]);
    const [conclusionList, setConclusionList] = useState<any[]>([]);
    const [read, setRead] = useState<boolean>(false);
    const [updateListProfessionnals, setUpdateListProfessionnals] = useState(false);
    const [activeLoadingIndicator, setActiveLoadingIndicator] = useState<boolean>(false);
    const [fetchingPatientData, setFetchingPatientData] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);

    return (
        <PatientContext.Provider
            value={{
                currentPatientId,
                setCurrentPatientId,

                justAddedPatient,
                setJustAddedPatient,

                currentPatientProgram,
                setCurrentPatientProgram,

                currentPatientAntecedant,
                setCurrentPatientAntecedant,

                currentPatientConstant,
                setCurrentPatientConstant,

                currentPatientGoal,
                setCurrentPatientGoal,

                axis,
                setAxis,

                axisCompteur,
                setAxisCompteur,

                updateList,
                setUpdateList,

                patientDefaultStatus,
                setPatientDefaultStatus,

                currentLocationPathname,
                setCurrentLocationPathname,

                observationId,
                setObservationId,

                observatNotifData,
                setObservatNotifData,

                conclusionList,
                setConclusionList,

                read,
                setRead,

                updateListProfessionnals,
                setUpdateListProfessionnals,

                fetchingPatientData,
                setFetchingPatientData,

                selectedPatient,
                setSelectedPatient,

                activeLoadingIndicator,
                setActiveLoadingIndicator,
            }}
        >
            {children}
        </PatientContext.Provider>
    );
};

export default PatientProvider;
