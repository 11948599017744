import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteRequestResponse, GoalItem, OptimizedItem, PostGoalPayload } from 'src/interfaces/interfaces';
import GoalService from 'src/services/Goals/goal.service';
import { defaultGoalPaylopad } from 'src/views/Settings/Goals/Goals.utils';
import { useFetchGoalsRQHooks } from './ReactQueryHooks/useGoalRQHooks';
import { pathologyIds, sortByIds } from 'src/utils/utils';

interface IProps {
    toggleModal?: Function;
    setPayload?: Dispatch<SetStateAction<PostGoalPayload>>;
    setUpdateList?: Dispatch<SetStateAction<boolean>>;
    setModalConfirmVisible?: Dispatch<SetStateAction<boolean>>;
    setGoal?: Dispatch<SetStateAction<GoalItem>>;
    setJustAddedGoalIds?: Dispatch<SetStateAction<number[]>>;
    callBack?: Function;
}

const useGoalHooks = ({ toggleModal, setPayload, setUpdateList, setModalConfirmVisible, setGoal,setJustAddedGoalIds, callBack }: IProps) => {
    const [saving, setSaving] = useState<boolean>(false);
    const { data, isLoading: fetchingGoals, refetch } = useFetchGoalsRQHooks();
    const goals = sortByIds(data ?? []);

    const successfull = (message: string, response?: DeleteRequestResponse) => {
        if (response?.code === 409) {
            toast.error("Impossible de supprimer l'objectif car c'est déjà lié à d'autre donnée");
            setModalConfirmVisible?.((prev) => !prev);
        } else if (response?.code === 400) {
            toast.error('Objectif existe déjà');
        } else {
            setUpdateList?.((prev) => !prev);
            toggleModal?.();
            setGoal?.({} as GoalItem);
            setModalConfirmVisible?.((prev) => !prev);
            toast.success(message, { style: { zIndex: 150 } });
            setPayload?.(defaultGoalPaylopad);
            callBack?.();
        }
    };

    const failed = (message: string) => {
        toast.error(message);
        setSaving(false);
    };

    const optimizedGoals = useMemo(() => {
        if (goals?.length) {
            const optimized = Array.from(goals, (item: { id: number; goal: string; pathologie: OptimizedItem[] }) => ({
                id: item?.id,
                name: item?.goal,
                pathologyIds: pathologyIds(item?.pathologie) ?? [],
            }));
            return optimized;
        }
        return [];
    }, [goals]);

    const handleCreateNewGoal = ({ payload }: { payload: PostGoalPayload }) => {
        setSaving(true);
        const finalPayload = {
            ...payload,
            knowledgeIds: payload.knowledgeIds?.filter((knowledgeId) => knowledgeId > 0),
        };
        GoalService.PostNewGoal({ payload: finalPayload })
            .then((res) => {
                setJustAddedGoalIds?.((prev) => [...prev, res?.data?.data?.id]);
                setSaving(false);
                refetch();
                if (res?.data?.code === 400) toast.error('Objectif existe déjà');
                else successfull("Création de l'objectif réussie");
            })
            .catch(() => failed("Une erreur est survenue lors du création de l'objectif"));
    };

    const handleEditGoal = ({ goalId, payload }: { goalId: number; payload: PostGoalPayload }) => {
        setSaving(true);
        GoalService.EditGoal({ goalId, payload })
            .then(() => {
                setSaving(false);
                refetch();
                successfull("Modification de l'objectif réussie");
            })
            .catch(() => failed("Une erreur est survenue lors du modification de l'objectif"));
    };

    const handleDeleteGoal = ({ goalId }: { goalId: number }) => {
        setSaving(true);
        GoalService.DeleteGoal({ goalId })
            .then((response: any) => {
                setSaving(false);
                refetch();
                successfull('Objectif supprimé', response?.data);
            })
            .catch(() => failed("Une erreur est survenue lors de suppression de l'objectif"));
    };

    return {
        handleCreateNewGoal,
        saving,

        goals,
        optimizedGoals,
        fetchingGoals,

        handleDeleteGoal,
        handleEditGoal,
    };
};

export default useGoalHooks;
