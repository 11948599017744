import { FC, useEffect, useState } from 'react';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import EventModal from 'src/views/MyAgenda/EventModal';
import useFetchUpstairs from 'src/hooks/useFetchUpstairs';
import { Box } from '@mui/material';
import useAgendaHours from '../../../../hooks/useAgendaHours';
import CustomFullCalendar from 'src/components/CustomFullCalendar/CustomFullCalendar';
import { defaultEventDate } from 'src/components/CustomFullCalendar/calendarUtils';
import { PlanningAgendaFilters } from 'src/interfaces/interfaces';
import { handleFetchEvents } from 'src/components/CustomFullCalendar/event-utils';

interface PatientAgendaProps {
    patientId: number;
    defaultPatient?: any;
    isActor?: any;
    setViewType: any;
    viewType: any;
    isFromInfos?: any;
    setIsFromInfos?: any;
}

const PatientAgenda: FC<PatientAgendaProps> = (props) => {
    const { patientId, isActor } = props;
    const [modalEvent, setModalEvent] = useState<boolean>(false);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [createEventFromBtnAction, setCreateEventFromBtnAction] = useState<boolean>(false);
    const [specialityId, setSpecialityId] = useState<number>(0);
    const [loadingPlanningEvent, setLoadingPlanningEvent] = useState<boolean>(false);
    const [selectedTime, setSelectedTime] = useState<any>(null);
    const [selectedEventData, setSelectedEventData] = useState<any>(null);
    const [droppedEventId, setDroppedEventId] = useState<number>(0);
    const [newSpecialityInfos, setNewSpecialityInfos] = useState<any>(null);
    const toggleModal = () => setModalEvent(!modalEvent);
    const { handleFetchUpstairs } = useFetchUpstairs();
    const [isActorStatus, setIsActorStatus] = useState(isActor);
    const [newEventDate, setNewEventDate] = useState<string>('');
    const [events, setEvents] = useState([]);

    const [droppedEventDateTime, setDroppedEventDateTime] = useState<{
        start: any;
        end: any;
    }>({
        start: '',
        end: '',
    });

    const { handleFetchHoursData, timeSlot, time } = useAgendaHours();

    const dataForWeeklyEvent = selectedEventData;

    const handleFetchData = async () => {
        const filters: PlanningAgendaFilters = {
            patientId,
        };

        handleFetchEvents({
            checkedDate: defaultEventDate(),
            filters,
            setFetching: setLoadingPlanningEvent,
            setEvents,
        });
    };

    useEffect(() => {
        handleFetchData();
        handleFetchUpstairs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, updateList, isActorStatus]);

    useEffect(() => {
        handleFetchHoursData();
    }, []);

    return (
        <Box style={{}}>
            <Box
                style={{
                    flexGrow: 1,
                    overflowY: 'scroll',
                    background: 'white',
                    height: 'calc(100vh - 230px)',
                }}
            >
                {loadingPlanningEvent ? (
                    <div style={{ padding: 20 }}>
                        <CustomLoader isPlanning loadingNumber={4} />
                    </div>
                ) : (
                    <CustomFullCalendar
                        isPatientAgenda
                        setNewEventDate={setNewEventDate}
                        toggleCreateEventModal={toggleModal}
                        events={events}
                        setUpdateList={setUpdateList}
                        setDroppedEventId={setDroppedEventId}
                        setDroppedEventDateTime={setDroppedEventDateTime}
                        timeSlot={timeSlot}
                        timeInterval={time}
                    />
                )}
            </Box>

            <EventModal
                isPatientAgenda
                patientId={patientId}
                setUpdateList={setUpdateList}
                specialityId={specialityId}
                modalOpen={modalEvent}
                setSpecialityId={setSpecialityId}
                modalToggle={toggleModal}
                selectedTime={selectedTime}
                selectedEventData={selectedEventData}
                dataForWeeklyEvent={dataForWeeklyEvent}
                createEventFromBtnAction={createEventFromBtnAction}
                setCreateEventFromBtnAction={setCreateEventFromBtnAction}
                droppedEventId={droppedEventId}
                newEventDate={newEventDate}
                newSpecialityInfos={newSpecialityInfos}
                setNewSpecialityInfos={setNewSpecialityInfos}
                setDroppedEventId={setDroppedEventId}
                setNewEventDate={setNewEventDate}
                droppedEventDateTime={droppedEventDateTime}
            />
        </Box>
    );
};

export default PatientAgenda;
