import Box from '@mui/material/Box';
import { FC, useContext, useEffect, useState } from 'react';
import useStyles, { sxStyles } from './styles';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { useSelector } from 'react-redux';
import CustomEmptyData from 'src/components/CustomEmptyData';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import EventModal from 'src/views/MyAgenda/EventModal';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import useFetchUpstairs from 'src/hooks/useFetchUpstairs';
import ProgramService from 'src/services/Program/program.service';
import { programAgendaInfos } from '../../ProgramLogic';
import { PlanningAgendaFilters, ProgramStatistic, ProgramStatItem } from 'src/interfaces/interfaces';
import CustomFullCalendar from 'src/components/CustomFullCalendar/CustomFullCalendar';
import useAgendaHours from 'src/hooks/useAgendaHours';
import { handleFetchEvents } from 'src/components/CustomFullCalendar/event-utils';
import { defaultEventDate } from 'src/components/CustomFullCalendar/calendarUtils';
import { CustomText } from 'src/components/Text/CustomText';

interface ProgramAgendaProps {
    programId: number;
}

const ProgramAgenda: FC<ProgramAgendaProps> = (props) => {
    const { programId } = props;
    const classes = useStyles();
    const [modalEvent, setModalEvent] = useState<boolean>(false);
    const [createEventFromBtnAction, setCreateEventFromBtnAction] = useState<boolean>(false);
    const [specialityId, setSpecialityId] = useState<number>(0);
    const [loadingPlanningEvent, setLoadingPlanningEvent] = useState<boolean>(false);
    const [selectedTime, setSelectedTime] = useState<any>(null);
    const [selectedEventData, setSelectedEventData] = useState<any>(null);
    const [statData, setStatData] = useState<ProgramStatistic>({ canceled: 0, honored: 0, total: 0 });
    const toggleModal = () => setModalEvent(!modalEvent);
    const { currentDate, setSelectedEvent } = useContext(AgendaContext);
    const { handleFetchUpstairs } = useFetchUpstairs();

    const hangleFetchStatData = () => {
        setLoadingPlanningEvent(true);
        ProgramService.getProgramStatistic(programId)
            .then((res: any) => {
                setLoadingPlanningEvent(false);
                setStatData(res?.data?.data);
            })
            .catch(() => {
                setLoadingPlanningEvent(false);
            });
    };

    const finalDataToUse = useSelector((state: any) => state?.planningEvents?.data);
    const isEmpty = finalDataToUse?.length === 0;
    const dataForWeeklyEvent = selectedEventData;

    const { handleFetchHoursData, timeSlot, time } = useAgendaHours();
    const [newEventDate, setNewEventDate] = useState<string>('');
    const [events, setEvents] = useState([]);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [droppedEventId, setDroppedEventId] = useState<number>(0);
    const [newSpecialityInfos, setNewSpecialityInfos] = useState<any>(null);
    const [droppedEventDateTime, setDroppedEventDateTime] = useState<{
        start: any;
        end: any;
    }>({
        start: '',
        end: '',
    });

    const handleFetchData = async () => {
        const filters: PlanningAgendaFilters = {
            checkedPrograms: [programId],
        };

        handleFetchEvents({
            checkedDate: defaultEventDate(),
            filters,
            setFetching: setLoadingPlanningEvent,
            setEvents,
        });
    };

    useEffect(() => {
        handleFetchData();
        handleFetchUpstairs();
        hangleFetchStatData();
        handleFetchHoursData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDate, updateList, programId]);

    return (
        <Box sx={sxStyles.flexRow}>
            <Box sx={{ flex: 4 }} className={classes.container}>
                <Box
                    sx={{ flex: 8, padding: 1 }}
                    style={{
                        overflow: 'hidden',
                        overflowY: 'scroll',
                    }}
                >
                    {loadingPlanningEvent ? (
                        <CustomLoader isPlanning loadingNumber={4} />
                    ) : isEmpty ? (
                        <CustomEmptyData />
                    ) : (
                        <CustomFullCalendar
                            setNewEventDate={setNewEventDate}
                            toggleCreateEventModal={toggleModal}
                            events={events}
                            setUpdateList={setUpdateList}
                            setDroppedEventId={setDroppedEventId}
                            setDroppedEventDateTime={setDroppedEventDateTime}
                            timeSlot={timeSlot}
                            timeInterval={time}
                        />
                    )}
                </Box>

                <EventModal
                    setUpdateList={setUpdateList}
                    specialityId={specialityId}
                    modalOpen={modalEvent}
                    setSpecialityId={setSpecialityId}
                    modalToggle={toggleModal}
                    selectedTime={selectedTime}
                    selectedEventData={selectedEventData}
                    dataForWeeklyEvent={dataForWeeklyEvent}
                    createEventFromBtnAction={createEventFromBtnAction}
                    setCreateEventFromBtnAction={setCreateEventFromBtnAction}
                    droppedEventId={droppedEventId}
                    newEventDate={newEventDate}
                    newSpecialityInfos={newSpecialityInfos}
                    setNewSpecialityInfos={setNewSpecialityInfos}
                    setDroppedEventId={setDroppedEventId}
                    setNewEventDate={setNewEventDate}
                    droppedEventDateTime={droppedEventDateTime}
                />
            </Box>

            <Box sx={sxStyles.statContainer}>
                <CustomText
                    style={{ textAlign: 'center', marginBottom: 15 }}
                    isBreefSerif
                    color={baseColors.green.primary}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                    fontSize={baseFontSize.sl}
                >
                    Statistiques
                </CustomText>

                <Box>
                    {programAgendaInfos(statData)?.map((item: ProgramStatItem) => (
                        <Box key={item.id} sx={sxStyles.programAgendaInfos}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CustomText
                                    style={{ width: 220 }}
                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                    fontSize={baseFontSize.mini}
                                >
                                    {item.label}
                                </CustomText>
                                <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={baseFontSize.sm}>
                                    :
                                </CustomText>

                                <CustomText
                                    style={{
                                        marginTop: 4,
                                        marginLeft: 5,
                                        textAlign: 'center',
                                    }}
                                    fontSize={baseFontSize.mini}
                                >
                                    {item.value}
                                </CustomText>
                            </div>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default ProgramAgenda;
