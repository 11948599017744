import { Box, Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { BackButton } from 'src/components/Button/BackButton';
import { CustomButton } from 'src/components/Button/CustomButton';
import { CustomRadio } from 'src/components/InputField/CustomRadio';
import { InputField } from 'src/components/InputField/InputField';
import { CustomText } from 'src/components/Text/CustomText';
import {
    baseBorderRadius,
    baseColors,
    baseFontFamily,
    baseFontSize,
    baseScreenHeight,
} from 'src/utils/constants/baseStyles';
import { PatientRegistrationPayload } from 'src/interfaces/interfaces';
import {
    isNotBlank,
    isPhoneNumber,
    isValidMail,
    isNumber,
    isValidPostalCode,
    isValidRpps,
} from 'src/utils/helpers/InputValidator';
import { PatientService } from 'src/services/Patients';
import CustomModal from 'src/components/CustomModal';
import { message, title } from 'src/utils/helpers/helpers';
import { FakeSelect } from 'src/components/InputField/FakeSelect';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { PatientContext } from 'src/providers/PatientProvider';
import CustomDatePicker from 'src/components/CustomDatePicker';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { toast } from 'react-toastify';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { getAttendeesSpeciality } from 'src/services/Attendees';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import useProfessionals from 'src/hooks/useProfessionals';
import { fullName } from 'src/utils/utils';
import { Spacer } from 'src/components/Spacer/Spacer';
import MailJetableService from 'src/services/MailJetable/mailJetable.service';
import { ROUTES } from 'src/routes/routes';
import { defaultPatientPayload } from './PatientsUtils';
import useEducationalDiagnosis from 'src/hooks/useEducationalDiagnosis';
import PatientFormInput from './PatientFormInput';
import PhoneInput from 'react-phone-input-2';
import { PatientFormBtn } from './PatientFormBtn';
import { CustomFormCard } from 'src/components/CustomCard/CustomFormCard';
import { UserContext } from 'src/providers/UserProvider';
import { CustomCheckBox } from 'src/components/InputField/CustomCheckBox';
import { URER_ROLES } from 'src/utils/constants/enums';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.leftOnly,
    },
});

interface IProps {
    setUpdate?: Dispatch<SetStateAction<boolean>>;
}

export const PatientForm: FC<IProps> = ({ setUpdate }) => {
    const classes = useStyles();

    const [patient, setPatient] = useState<PatientRegistrationPayload>(defaultPatientPayload);
    const [professional, setProfessional] = useState(defaultPatientPayload);
    const [assistant, setAssistant] = useState(defaultPatientPayload);

    const { setSelectedPatient } = useContext(PatientContext);

    const [addProfessionalLoading, setAddProfessionalLoading] = useState(false);
    const [addAssistantLoading, setAddAssistantLoading] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [isDoctorChecked, setIsDoctorChecked] = useState(false);
    const [lockInput, setLoackInput] = useState(false);
    const [disableAddProfSante, setDisableAddProfSante] = useState(false);
    const [postTraitmentMessage, setPostTraitementMessage] = useState(message.patientAddedSuccessfully);
    const [modalPostTraitementVisible, setModalPostTraitementVisible] = useState(false);
    const [modalPostTraitementAssitant, setModalPostTraitementAssitant] = useState(false);
    const [modalProfessionalOrAssistantVisible, setModalProfessionalOrAssistantVisible] = useState(false);
    const [modalProfessionalOrAssistantTitle, setModalProfessionalOrAssistantTitle] = useState(title.professional);
    const [assistants, setAssistants] = useState([]);
    const [medecinTraitantId, setMedecinTraitantId] = useState(0);

    const history = useHistory();
    const { setUpdateListProfessionnals, selectedPatient } = useContext(PatientContext);
    const { professionalsante, fetchingProfessionals } = useProfessionals();

    const [loadingAssistants, setLoadingAssistants] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const handleBirthDateChange = (date: Date | null) => setPatient((old) => ({ ...old, birthDate: date }));

    const { fetchEducationalDiagnosis, fetching: fetchingDE } = useEducationalDiagnosis({
        patientId: patient?.id ?? 0,
    });

    useEffect(() => {
        if (patient && patient.id && patient?.id > 0) {
            fetchEducationalDiagnosis();
        }
    }, [patient]);

    const OpenModalProfessionalOrAssistant = (type: string) => {
        if (type === 'assistants') {
            setModalProfessionalOrAssistantTitle(title.assistant);
        } else {
            setModalProfessionalOrAssistantTitle(title.professional);
        }
        setModalProfessionalOrAssistantVisible(true);
    };

    const validProfesionnalData = () => {
        return (
            isNotBlank(professional?.firstName) &&
            professional?.specialityTypeId > 0 &&
            isValidRpps(professional?.rpps) &&
            isValidMail(professional?.email) &&
            isPhoneNumber(professional?.phoneNumber)
        );
    };

    const validAssistantData = () => {
        return isNotBlank(assistant?.firstName) && isValidMail(assistant?.email);
    };

    const backProfil = () => {
        setModalPostTraitementVisible(false);
        history.push(ROUTES.patientsDetails);
    };

    const handleFetchDataSpeciality = async () => {
        setFetchingSpeciality(true);
        getAttendeesSpeciality().then((response: any) => {
            const responses = response?.data?.data?.items;
            setSpecialites(responses);
            setFetchingSpeciality(false);
        });
    };

    const [fetchingSpeciality, setFetchingSpeciality] = useState<boolean>(false);
    const [specialites, setSpecialites] = useState<Array<any>>([]);

    const specialisteData = [{ id: 0, name: 'Sélectionner une spécialité' }, ...specialites];

    const performAddProfessional = () => {
        setAddProfessionalLoading(true);
        setLoackInput(true);
        MailJetableService.getMailJetable().then((response) => {
            let testEmail = professional.email;
            let afterArobase = testEmail.split('@')[1];
            let mailJetableArray: any[] = [];
            let responses = response?.data?.data;
            responses.map((element: any) => mailJetableArray.push(element.name.toLowerCase()));
            if (mailJetableArray.includes(afterArobase.toLowerCase())) {
                setAddProfessionalLoading(false);
                setLoackInput(false);
            } else {
                PatientService.postProfessionals({ ...professional, isExternal: 1, hasETP: false, checkETP: 0 }).then(
                    (response) => {
                        const res = response.data?.data;
                        if (response.data.code === 200) {
                            const justAddedDoctor = res;
                            //show success modal
                            setPostTraitementMessage(message.professionalAddedSuccessfully);
                            setProfessional(defaultPatientPayload);
                            toast.success(
                                'Vous pouvez à présent faire votre choix parmi la liste de professionnels de santé.',
                            );
                            setUpdateData((prev) => !prev);
                            setProfessional(defaultPatientPayload);
                            setPatient((prev) => {
                                let res = {
                                    ...prev,
                                    professionals: [...prev?.professionals, justAddedDoctor?.id],
                                };

                                if (isDoctorChecked) {
                                    res.medecinTraitantId = justAddedDoctor.id;
                                    setMedecinTraitantId(justAddedDoctor.id);
                                }
                                return res;
                            });
                            setIsDoctorChecked(false);
                            setUpdateListProfessionnals((prev: any) => !prev);
                        } else if (response.data.code === 400) {
                            toast.warn("Le professionel existe déjà dans l'application.");
                            setPostTraitementMessage(message.professionalAlreadyExist);
                            // (professsetProfessionalional);
                        }
                        setAddProfessionalLoading(false);
                        setLoackInput(false);
                    },
                );
            }
        });
    };

    const { bloodPressure, ...assistantWithoutBloodPressure } = assistant;

    const performAddAssistant = () => {
        setAddAssistantLoading(true);
        setLoackInput(true);
        MailJetableService.getMailJetable().then((response) => {
            let testEmail = assistant.email;
            let afterArobase = testEmail.split('@')[1];
            let mailJetableArray: any[] = [];
            let responses = response?.data?.data;
            responses.map((element: any) => mailJetableArray.push(element.name.toLowerCase()));
            if (mailJetableArray.includes(afterArobase.toLowerCase())) {
                setAddAssistantLoading(false);
                setLoackInput(false);
            } else {
                PatientService.postAssistants({ ...assistantWithoutBloodPressure, patientId: selectedPatient?.id })
                    .then((response) => {
                        if (response.data.code === 200) {
                            //show success modal
                            performGetPatientById(selectedPatient?.id);
                            setPostTraitementMessage(message.assistantAddedSuccessfully);
                            setAssistant(defaultPatientPayload);
                            setModalPostTraitementAssitant(true);
                        } else if (response.data.code === 400) {
                            setPostTraitementMessage(message.assistantAlreadyExist);
                            // setAssistant(assistant);
                            setModalPostTraitementAssitant(true);
                        }
                        setAddAssistantLoading(false);
                        setLoackInput(false);
                    })
                    .catch((error) => {
                        setPostTraitementMessage(message.assistantNotInserted);
                    });
            }
        });
    };

    const performGetPatientById = (idPatient: any) => {
        PatientService.getPatient(idPatient)
            .then((res) => {
                const result = res?.data?.data;
                setSelectedPatient((prev: any) => {
                    return result;
                });
            })
            .catch(() => {});
    };

    const handleFetchData = (query?: string, patientId?: number) => {
        setLoadingAssistants(true);
        PatientService.getAssistants(query, patientId)
            .then((response) => {
                const responses = response?.data?.data;
                const optimizedData: any = Array.from(responses ?? [], (item: any) => ({
                    id: item?.id,
                    name: fullName(item?.lastName, item?.firstName),
                }));
                setAssistants(optimizedData);
                setLoadingAssistants(false);
            })
            .catch(() => setLoadingAssistants(false));
    };

    useEffect(() => {
        handleFetchData('', selectedPatient?.id);
    }, [updateData, selectedPatient]);

    useEffect(() => {
        if (modalProfessionalOrAssistantVisible) {
            handleFetchData('', selectedPatient?.id);
        }
    }, [modalProfessionalOrAssistantVisible]);

    useEffect(() => {
        handleFetchDataSpeciality();
    }, []);

    useEffect(() => {
        if (selectedPatient?.id) {
            const professionalIds: Array<number> = [];
            selectedPatient?.professionals?.forEach((item: any) => {
                if (item?.professional?.roles?.includes(URER_ROLES['DOCTOR']))
                    professionalIds.push(item?.professional?.id);
            });

            setPatient((prev) => ({
                ...prev,
                id: selectedPatient?.id,
                firstName: selectedPatient?.firstName ?? '',
                lastName: selectedPatient?.lastName ?? '',
                email: selectedPatient?.email ?? '',
                phoneNumber: selectedPatient?.phoneNumber ?? '',
                profession: selectedPatient?.profession ?? '',
                address: selectedPatient?.address ?? '',
                birthPlace: selectedPatient?.birthPlace ?? '',
                additionalAdressInfo: selectedPatient?.additionalAdressInfo ?? '',
                postalCode: selectedPatient?.postalCode ?? '',
                city: selectedPatient?.city ?? '',
                professionals: professionalIds ?? [],
                civility: selectedPatient?.civility ?? 0,
                sex: selectedPatient?.civility ?? 0,
                birthDate: selectedPatient?.birthDate ? new Date(selectedPatient?.birthDate) : new Date(),
            }));
            const medecinTraitant = selectedPatient?.professionals?.find(
                (medecin: any) => medecin?.professional?.isMedecinTraitant,
            );
            setMedecinTraitantId(medecinTraitant?.professional?.id ?? 0);
            setPatient((prev)=>({
                ...prev,
                medecinTraitantId : medecinTraitant?.professional?.id
            }))
        }
    }, [selectedPatient]);

    useEffect(() => {
        setDisableAddProfSante(!selectedPatient?.finishedDE);
    }, []);

    const handleSwitchDoctor = (medecinTrId: number) => {
        setMedecinTraitantId(medecinTrId);

        setPatient((old) => ({ ...old, medecinTraitantId: medecinTrId ?? 0 }));
    };

    const disableEmailInput = patient?.id! > 0;
    const disableProcheAidant = !selectedPatient?.id;

    const checkValidRpps = (checked: boolean) => {
        if (checked) {
            return (
                <CustomText
                    style={{ marginTop: -10, marginBottom: 20 }}
                    fontFamily={baseFontFamily.BreeSerif}
                    fontSize={12}
                    color={baseColors.orange.dark}
                >
                    Le numéro RPPS doit avoir au moins 11 chiffres
                </CustomText>
            );
        }
    };

    return (
        <Box className={classes.container}>
            <CustomText
                style={{ textAlign: 'center', paddingTop: 10 }}
                fontFamily={baseFontFamily.BreeSerif}
                fontSize={baseFontSize.title}
            >
                {patient?.id ? "Modification d'un patient" : 'Créer un nouveau patient'}
            </CustomText>

            <Grid style={{ width: 230, paddingLeft: 20, paddingTop: 10, cursor: 'pointer' }}>
                <BackButton />
            </Grid>

            <div style={{ overflowY: 'scroll', paddingLeft: 20 }}>
                {loading ? (
                    <div style={{ marginTop: '24%', marginLeft: '45%', marginRight: 'auto' }}>
                        <CustomCircleLoader height={70} width={70} />
                    </div>
                ) : (
                    <Grid padding={2} container direction="column">
                        {/**First row */}
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item style={{ flex: 1 }}>
                                <CustomText fontSize={18}>Civilité :</CustomText>
                                <Grid item>
                                    <Grid style={{ marginLeft: -10 }} container direction={'row'}>
                                        {patient?.sex === 2 ? (
                                            <>
                                                <Grid item>
                                                    <CustomRadio
                                                        checked={patient?.civility === 0}
                                                        onClick={() => {
                                                            setPatient((old) => ({ ...old, civility: 0 }));
                                                        }}
                                                        label="Mme"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <CustomRadio
                                                        checked={patient?.civility === 1}
                                                        onClick={() => {
                                                            setPatient((old) => ({ ...old, civility: 1 }));
                                                        }}
                                                        label="M."
                                                    />
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Grid item>
                                                    <CustomRadio
                                                        checked={patient?.civility === 0}
                                                        onClick={() => {
                                                            setPatient((old) => ({ ...old, civility: 0, sex: 0 }));
                                                        }}
                                                        label="Mme"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <CustomRadio
                                                        checked={patient?.civility === 1}
                                                        onClick={() => {
                                                            setPatient((old) => ({ ...old, civility: 1, sex: 1 }));
                                                        }}
                                                        label="M."
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item style={{ flex: 1 }}>
                                <CustomText fontSize={18}>Genre :</CustomText>
                                <Grid container>
                                    <Grid style={{ marginLeft: -10 }} container direction={'row'}>
                                        <Grid item>
                                            <CustomRadio
                                                checked={patient?.sex === 0}
                                                onClick={() => {
                                                    setPatient((old) => ({ ...old, sex: 0, civility: 0 }));
                                                }}
                                                label={'Féminin'}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <CustomRadio
                                                checked={patient?.sex === 1}
                                                onClick={() => {
                                                    setPatient((old) => ({ ...old, sex: 1, civility: 1 }));
                                                }}
                                                label={'Masculin'}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <CustomRadio
                                                checked={patient?.sex === 2}
                                                onClick={() => {
                                                    setPatient((old) => ({ ...old, sex: 2, civility: 1 }));
                                                }}
                                                label={'Autre'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Spacer />

                        {/**Second row */}
                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <PatientFormInput
                                    upper
                                    defaultValue={patient?.firstName}
                                    label="Nom de naissance"
                                    keyValue="firstName"
                                    placeholder="Taper le nom de naissance"
                                    setPatient={setPatient}
                                    lockProfessionalInput={lockInput}
                                    errorMessage="Nom Obligatoire"
                                    required
                                />

                                <PatientFormInput
                                    label="Prénom(s)"
                                    keyValue="lastName"
                                    defaultValue={patient?.lastName}
                                    placeholder="Taper le ou les prénoms"
                                    setPatient={setPatient}
                                    lockProfessionalInput={lockInput}
                                    required
                                />
                            </Grid>
                        </Grid>

                        {/**third row */}
                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid item style={{ flex: 1 }}>
                                    <CustomText style={{ marginBottom: 7 }} fontSize={16}>
                                        Date de naissance :
                                    </CustomText>

                                    <CustomDatePicker
                                        height={37}
                                        likeSelect
                                        width={300}
                                        validator={true}
                                        setValue={handleBirthDateChange}
                                        value={patient?.birthDate ?? null}
                                    />
                                </Grid>

                                <PatientFormInput
                                    defaultValue={patient?.birthPlace}
                                    label="Lieu de naissance"
                                    keyValue="birthPlace"
                                    placeholder="Toulouse"
                                    setPatient={setPatient}
                                    lockProfessionalInput={lockInput}
                                    errorMessage="Lieu de naissance Obligatoire"
                                />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <PatientFormInput
                                    defaultValue={patient?.address}
                                    label="Adresse"
                                    keyValue="address"
                                    placeholder="Taper l'adresse"
                                    setPatient={setPatient}
                                    lockProfessionalInput={lockInput}
                                    errorMessage="Adresse invalide"
                                    required
                                />

                                <PatientFormInput
                                    defaultValue={patient?.additionalAdressInfo}
                                    label="Appartement, Etage"
                                    keyValue="additionalAdressInfo"
                                    placeholder="Taper l'adresse supplémentaire"
                                    setPatient={setPatient}
                                    lockProfessionalInput={lockInput}
                                    errorMessage="Appartement invalide"
                                />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <PatientFormInput
                                    maxLength={5}
                                    defaultValue={patient?.postalCode}
                                    label="Code Postal (Français)"
                                    keyValue="postalCode"
                                    placeholder="Taper votre code postal"
                                    setPatient={setPatient}
                                    lockProfessionalInput={lockInput}
                                    errorMessage="Code postal invalide"
                                    validator={isValidPostalCode}
                                    required
                                />
                                <PatientFormInput
                                    defaultValue={patient?.city}
                                    label="Ville"
                                    keyValue="city"
                                    placeholder="Taper votre ville"
                                    setPatient={setPatient}
                                    lockProfessionalInput={lockInput}
                                    errorMessage="Ville invalide"
                                />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid id="patient-phone-number" item style={{ flex: 1 }}>
                                    <CustomText
                                        style={{ marginBottom: 3 }}
                                        color={baseColors.black}
                                        fontSize={baseFontSize.sl}
                                    >
                                        Téléphone :
                                    </CustomText>
                                    <PhoneInput
                                        //isValid={(inputNumber) => inputNumber?.length > 10}
                                        country="fr"
                                        inputStyle={{
                                            backgroundColor: 'transparent',
                                            width: '95%',
                                            height: 40,
                                        }}
                                        countryCodeEditable={false}
                                        value={patient?.phoneNumber}
                                        onChange={(phone) =>
                                            setPatient((old) => ({ ...old, phoneNumber: '+' + phone }))
                                        }
                                    />
                                    <Spacer />
                                </Grid>

                                <PatientFormInput
                                    defaultValue={patient?.email}
                                    label="Adresse mail"
                                    keyValue="email"
                                    placeholder="Entrer l'adresse mail"
                                    setPatient={setPatient}
                                    lockProfessionalInput={lockInput || disableEmailInput}
                                    errorMessage="Adresse email invalide"
                                    validator={isValidMail}
                                    required
                                />
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid item style={{ flex: 1 }}>
                                    <InputField
                                        label="Profession :"
                                        placeholder="Taper le nom de la profession"
                                        validator={isNotBlank}
                                        disabled={lockInput}
                                        errorMessage="Obligatoire"
                                        onChange={(value: any) =>
                                            setPatient((old) => ({ ...old, profession: value.toString() }))
                                        }
                                        value={patient?.profession}
                                        width={'95%'}
                                        idSuffix="patient"
                                        required
                                    />
                                </Grid>
                                <Grid item style={{ flex: 1 }} />
                            </Grid>
                        </Grid>

                        {/**soignant  */}
                        <Grid
                            item
                            style={{
                                margin: 5,
                                padding: 20,
                                borderRadius: 10,
                                background: baseColors.green.light,
                            }}
                        >
                            <Grid
                                style={{ opacity: disableAddProfSante ? 0.5 : 1 }}
                                container
                                justifyContent={'center'}
                            >
                                <FakeSelect
                                    disabled={disableAddProfSante}
                                    onClick={() => OpenModalProfessionalOrAssistant('professionals')}
                                    style={{
                                        borderRadius: 7,
                                        color: baseColors.green.primary,
                                        background: baseColors.green.light,
                                        width: 400,
                                        padding: '10px 20px',
                                    }}
                                    title={`Liste des professionnels de santé ( ${patient?.professionals?.length} )`}
                                />
                            </Grid>

                            <Spacer height={40} />

                            <Grid container>
                                <Grid item flex={1}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item style={{ flex: 1 }}>
                                            <InputField
                                                disabled={disableAddProfSante}
                                                label="Nom  :"
                                                inversed
                                                validator={isNotBlank}
                                                errorMessage="Nom Obligatoire"
                                                placeholder="Taper le nom "
                                                onChange={(value: any) => {
                                                    setProfessional((old) => ({ ...old, firstName: value.toString() }));
                                                }}
                                                value={professional.firstName}
                                                width="95%"
                                                idSuffix="soignant"
                                            />
                                        </Grid>
                                        <Grid item style={{ flex: 1 }}>
                                            <InputField
                                                label="Prénom(s) :"
                                                inversed
                                                disabled={disableAddProfSante}
                                                validator={isNotBlank}
                                                errorMessage="Prénom Obligatoire"
                                                placeholder="Taper le prénom"
                                                onChange={(value: any) => {
                                                    setProfessional((old) => ({ ...old, lastName: value.toString() }));
                                                }}
                                                value={professional.lastName}
                                                width="95%"
                                                idSuffix="soignant"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={2}>
                                        <Grid item style={{ flex: 1 }}>
                                            <InputField
                                                inversed
                                                label="Adresse mail :"
                                                placeholder="Taper l'adresse mail"
                                                validator={isValidMail}
                                                errorMessage="email invalide"
                                                onChange={(value: any) => {
                                                    setProfessional((old) => ({ ...old, email: value.toString() }));
                                                }}
                                                value={professional.email}
                                                width="95%"
                                                idSuffix="soignant"
                                                disabled={disableAddProfSante}
                                            />
                                        </Grid>
                                        <Grid item style={{ flex: 1 }}>
                                            <InputField
                                                width="95%"
                                                placeholder="Taper le numéro RPPS"
                                                label="Numéro RPPS :"
                                                disabled={disableAddProfSante}
                                                onChange={(value: any) => {
                                                    setProfessional((old) => ({ ...old, rpps: value }));
                                                }}
                                                validator={isNumber}
                                                value={professional.rpps}
                                            />

                                            {professional?.rpps?.length < 11 && (
                                                <Grid
                                                    item
                                                    flexGrow={1}
                                                    justifyContent="center"
                                                    style={{ paddingLeft: '4%' }}
                                                >
                                                    {professional?.rpps?.length > 0 && professional?.rpps?.length < 11
                                                        ? checkValidRpps(true)
                                                        : checkValidRpps(false)}
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={2}>
                                        <Grid item style={{ flex: 1 }}>
                                            <CustomText fontSize={16}>Profession :</CustomText>
                                            <CustomSelect
                                                idSelect="speciality-type"
                                                label=""
                                                labelKey="name"
                                                width="95%"
                                                items={specialisteData}
                                                defaultValue={professional?.specialityTypeId ?? 0}
                                                onChange={(value: any) => {
                                                    setProfessional((old: any) => ({
                                                        ...old,
                                                        specialityTypeId: value ?? 0,
                                                    }));
                                                }}
                                                disabled={disableAddProfSante}
                                            />
                                        </Grid>

                                        <Grid id="professional-sante-phone-number" item style={{ flex: 1 }}>
                                            <CustomText
                                                style={{ marginBottom: 3 }}
                                                color={baseColors.black}
                                                fontSize={baseFontSize.sl}
                                            >
                                                Téléphone :
                                            </CustomText>
                                            <PhoneInput
                                                //isValid={(inputNumber) => inputNumber?.length > 10 || disableAddProfSante}
                                                country="fr"
                                                inputStyle={{
                                                    backgroundColor: 'transparent',
                                                    width: '95%',
                                                    height: 40,
                                                }}
                                                countryCodeEditable={false}
                                                value={professional.phoneNumber}
                                                onChange={(phone) =>
                                                    setProfessional((old) => ({ ...old, phoneNumber: '+' + phone }))
                                                }
                                                disabled={disableAddProfSante}
                                            />
                                            <Spacer />
                                        </Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={2}>
                                        <Grid item style={{ flex: 1 }}>
                                            <CustomCheckBox
                                                fontSize={14}
                                                onClick={() => setIsDoctorChecked(!isDoctorChecked)}
                                                checked={isDoctorChecked}
                                                label="Médecin traitant"
                                                disabled={disableAddProfSante}
                                            />
                                        </Grid>

                                        <Grid item style={{ flex: 1 }} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Spacer />

                            {fetchingDE && (
                                <>
                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <CustomCircleLoader height={40} width={40} />
                                    </Box>
                                    <Spacer />
                                </>
                            )}
                            {!fetchingDE && disableAddProfSante && (
                                <>
                                    <CustomText
                                        style={{ textAlign: 'center' }}
                                        fontFamily={baseFontFamily.BreeSerif}
                                        fontSize={baseFontSize.sm}
                                        color={baseColors.orange.dark}
                                    >
                                        ( Le patient n'a pas encore rempli son diagnostic éducatif. L'ajout d'un médecin
                                        traitant sera possible une fois que le patient aura rempli son diagnostic
                                        éducatif et qu'il aura donné son consentement. )
                                    </CustomText>

                                    <Spacer />
                                </>
                            )}

                            <Grid container justifyContent="center">
                                <CustomLoadingButton
                                    width="50%"
                                    loading={addProfessionalLoading}
                                    disabled={!validProfesionnalData() || disableAddProfSante}
                                    handleClick={() => performAddProfessional()}
                                    label="AJOUTER UN PROFESSIONNEL DE SANTÉ"
                                />
                            </Grid>
                        </Grid>

                        <Spacer />

                        {/**Aidant  */}
                        <Grid
                            item
                            style={{
                                margin: 5,
                                padding: 20,
                                borderRadius: 10,
                                background: baseColors.green.light,
                            }}
                        >
                            <Grid
                                style={{ opacity: disableProcheAidant ? 0.5 : 1 }}
                                container
                                justifyContent={'center'}
                            >
                                <FakeSelect
                                    disabled={disableProcheAidant}
                                    onClick={() => OpenModalProfessionalOrAssistant('assistants')}
                                    style={{
                                        borderRadius: 7,
                                        color: baseColors.green.primary,
                                        background: baseColors.green.light,
                                        width: 400,
                                        padding: '10px 20px',
                                    }}
                                    title={`Liste des proches ou aidants ( ${assistants?.length} )`}
                                />
                            </Grid>

                            <Spacer height={40} />

                            <Grid container>
                                <Grid flexGrow={1} item>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item style={{ flex: 1 }}>
                                            <InputField
                                                inversed
                                                disabled={disableProcheAidant}
                                                label="Nom :"
                                                placeholder="Taper le nom "
                                                validator={isNotBlank}
                                                errorMessage="Nom Obligatoire"
                                                onChange={(value: any) => {
                                                    setAssistant((old) => ({ ...old, firstName: value.toString() }));
                                                }}
                                                value={assistant.firstName}
                                                width="95%"
                                                idSuffix="aidant"
                                            />
                                        </Grid>

                                        <Grid item style={{ flex: 1 }}>
                                            <InputField
                                                inversed
                                                disabled={disableProcheAidant}
                                                label="Prénom(s) :"
                                                placeholder="Taper le prénom"
                                                validator={isNotBlank}
                                                errorMessage="Prénom Obligatoire"
                                                onChange={(value: any) => {
                                                    setAssistant((old) => ({ ...old, lastName: value.toString() }));
                                                }}
                                                value={assistant.lastName}
                                                width={'95%'}
                                                idSuffix="aidant"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container direction="row" spacing={2}>
                                        <Grid item style={{ flex: 1 }}>
                                            <InputField
                                                inversed
                                                disabled={disableProcheAidant}
                                                label="Relation  :"
                                                value={assistant.relation}
                                                placeholder="Compagnon "
                                                validator={isNotBlank}
                                                errorMessage="Relation Obligatoire"
                                                onChange={(value: any) => {
                                                    setAssistant((old) => ({ ...old, relation: value.toString() }));
                                                }}
                                                width="95%"
                                                idSuffix="aidant"
                                            />
                                        </Grid>

                                        <Grid item style={{ flex: 1 }}>
                                            <InputField
                                                inversed
                                                disabled={disableProcheAidant}
                                                label="Adresse mail :"
                                                placeholder="Taper l'adresse mail"
                                                validator={isValidMail}
                                                errorMessage="email invalide"
                                                onChange={(value: any) => {
                                                    setAssistant((old) => ({ ...old, email: value.toString() }));
                                                }}
                                                value={assistant.email}
                                                width="95%"
                                                idSuffix="aidant"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid id="assistant-phone-number" container direction="row" spacing={2}>
                                        <Grid item style={{ flex: 1 / 2 }}>
                                            <CustomText
                                                style={{ marginBottom: 3 }}
                                                color={baseColors.black}
                                                fontSize={baseFontSize.sl}
                                            >
                                                Téléphone :
                                            </CustomText>
                                            <PhoneInput
                                                //isValid={(inputNumber) => inputNumber?.length > 10}
                                                country="fr"
                                                inputStyle={{
                                                    backgroundColor: 'transparent',
                                                    width: '94%',
                                                    height: 40,
                                                }}
                                                countryCodeEditable={false}
                                                value={assistant.phoneNumber}
                                                disabled={disableProcheAidant}
                                                onChange={(phone) =>
                                                    setAssistant((old) => ({ ...old, phoneNumber: '+' + phone }))
                                                }
                                            />
                                            <Spacer />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Spacer />

                            { disableProcheAidant &&
                            <>
                                <CustomText
                                    style={{ textAlign: 'center' }}
                                    fontFamily={baseFontFamily.BreeSerif}
                                    fontSize={baseFontSize.sm}
                                    color={baseColors.orange.dark}
                                >
                                    ( L'ajout d'un proche ou aidant sera possible après la création du compte patient. )
                                </CustomText>

                                <Spacer />
                            </> 
                            }

                            <Grid item>
                                <Grid container justifyContent="center">
                                    <CustomLoadingButton
                                        width="50%"
                                        loading={addAssistantLoading}
                                        disabled={!validAssistantData()}
                                        handleClick={performAddAssistant}
                                        label="Ajouter un proche ou un aidant"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <PatientFormBtn
                            medecinTraitantId={medecinTraitantId}
                            setLoackInput={setLoackInput}
                            setPostTraitementMessage={setPostTraitementMessage}
                            setModalPostTraitementVisible={setModalPostTraitementVisible}
                            setLoading={setLoading}
                            payload={patient}
                            setPayload={setPatient}
                            setUpdate={setUpdate}
                        />
                    </Grid>
                )}
            </div>

            {/* PROF SANTE */}

            <CustomModal
                withMinHeight
                width={500}
                height={baseScreenHeight.newEventModal}
                title={
                    modalProfessionalOrAssistantTitle === title.professional
                        ? title.professionalList
                        : title.assistantsList
                }
                open={modalProfessionalOrAssistantVisible}
                toggleModal={() => setModalProfessionalOrAssistantVisible(false)}
            >
                <div style={{ marginTop: 10 }}>
                    {modalProfessionalOrAssistantTitle === title.professional ? (
                        <CustomFormCard
                            isHealthProfessional
                            medecinTraitantId={medecinTraitantId}
                            labelKey="name"
                            selectedRows={new Set(patient?.professionals)}
                            handleChange={(selected: Set<number>) =>
                                setPatient((old) => ({ ...old, professionals: Array.from(selected) }))
                            }
                            items={professionalsante}
                            maxItemNumber={professionalsante?.length ?? 10}
                            loading={fetchingProfessionals}
                            loadingNumber={10}
                            handleSwitchDoctor={handleSwitchDoctor}
                        />
                    ) : (
                        <CustomFormCard
                            labelKey="name"
                            selectedRows={new Set(patient?.assistants)}
                            handleChange={(selected: Set<number>) =>
                                setPatient((old) => ({ ...old, assistants: Array.from(selected) }))
                            }
                            items={assistants}
                            loading={loadingAssistants}
                            maxItemNumber={assistants?.length ?? 10}
                            loadingNumber={10}
                            readOnly={true}
                        />
                    )}
                </div>
            </CustomModal>

            <CustomModal open={modalPostTraitementVisible} toggleModal={() => setModalPostTraitementVisible(false)}>
                <Grid
                    container
                    style={{ marginTop: 10 }}
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    direction="column"
                >
                    <Grid item>
                        <CustomText style={{ marginBottom: 10 }} fontFamily={baseFontFamily.BreeSerif}>
                            {postTraitmentMessage}
                        </CustomText>
                    </Grid>
                    <Grid item>
                        <CustomButton
                            width={280}
                            size="small"
                            onClick={() => backProfil()}
                            label="Allez vers la liste de patients "
                        />
                    </Grid>
                </Grid>
            </CustomModal>

            <CustomModal open={modalPostTraitementAssitant} toggleModal={() => setModalPostTraitementAssitant(false)}>
                <Grid
                    container
                    style={{ marginTop: 10 }}
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    direction="column"
                >
                    <Grid item>
                        <CustomText style={{ marginBottom: 10 }} fontFamily={baseFontFamily.BreeSerif}>
                            {postTraitmentMessage}
                        </CustomText>
                    </Grid>
                    <Grid item>
                        <CustomButton
                            width={280}
                            size="small"
                            onClick={() => setModalPostTraitementAssitant(false)}
                            label="Retour à l'édition du patient"
                        />
                    </Grid>
                </Grid>
            </CustomModal>
        </Box>
    );
};
