import { Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import ProgramTypesService from 'src/services/Pathology/pathology.service';
import { Pathology } from 'src/interfaces/interfaces';
import styles from './styles';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { fullName, sortByIds, uniqByKeyValues } from 'src/utils/utils';
import { CustomText } from 'src/components/Text/CustomText';
import { tableCellHeadText, tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';
import { PathologyForm, defaultPathologieValue } from './PathologyForm';

export const PathologyPanel = () => {
    const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const [loadingPathology, setLoadingPathology] = useState(false);
    const [modalFormOpen, setModalFormOpen] = useState(false);
    const [pathologyIdToDelete, setPathologyIdToDelete] = useState(-1);
    const [pathology, setPathology] = useState<Pathology>(defaultPathologieValue);
    const [consultationData, setConsultationData] = useState<any>(null);
    const [pathologies, setPathologies] = useState<any[]>([]);
    const tableHeaders = [{ name: 'Nom' }, { name: 'Action' }];

    const onEdit = (data: any) => {
        let cloneData = structuredClone(data);

        let pathologyData = {
            id: cloneData?.id,
            name: cloneData?.name,
        };
        setPathology(pathologyData);
        setModalFormOpen(true);
    };

    const fetchPathology = (query?: string) => {
        setLoadingPathology(true);
        ProgramTypesService.getProgramTypes({ keywords: query })
            .then((response) => {
                const data = response?.data?.data;
                if (data) {
                    setPathologies(sortByIds(data));
                }
                setLoadingPathology(false);
            })
            .catch((e) => {
                setLoadingPathology(false);
                toast.error('Une erreur sur la récupération des ateliers');
            });
    };

    useEffect(() => {
        fetchPathology();
    }, [updateList]);

    const performDeletePathology = () => {
        const errorMessage = 'Erreur';
        return new Promise((res, rej) => {
            if (pathologyIdToDelete > 0) {
                ProgramTypesService.deleteProgramType(pathologyIdToDelete)
                    .then((response) => {
                        const statusCode = response?.data?.code;
                        if (statusCode === 409) {
                            toast.error(errorMessage);
                        } else {
                            toast.success("Suppression de la pathologie réussie !");
                            setUpdateList((prev: any) => !prev);
                        }
                        setPathologyIdToDelete(0);
                        setModalConfirmVisible((prev: any) => !prev);
                        setLoading(false);
                        res(response);
                    })
                    .catch((e) => {
                        rej(e);
                        setLoading(false);
                        toast.error(errorMessage);
                        setModalConfirmVisible((prev: any) => !prev);
                    });
            }
        });
    };
    const handleClick = () => {
        setConsultationData(null);
        setPathology(defaultPathologieValue);
        setModalFormOpen(true);
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.header}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des pathologies
                </CustomText>

                <CustomLoadingButton disabled={loading} label="Ajouter une nouvelle pathologie" handleClick={handleClick} />
            </Box>

            {loadingPathology ? (
                <SubMenusLoader />
            ) : pathologies?.length === 0 ? (
                <div style={{ paddingBottom: 150 }}>
                    <CustomEmptyData />
                </div>
            ) : (
                <TableContainer
                    component={Paper}
                    style={{ overflow: 'auto', maxHeight: baseScreenHeight.tableContainerHeight }}
                >
                    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header, index) => (
                                    <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                        {tableCellHeadText(header.name)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {pathologies.map((row, index) => (
                                <>
                                    <TableRow
                                        key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '& .MuiTableCell-root': {
                                                padding: '5px 15px 5px 15px',
                                            },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {tableCellText(row.name)}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => onEdit(row)}>
                                                <EditOutlinedIcon
                                                    sx={{
                                                        fontSize: 'medium',
                                                        color: baseColors.green.primary,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setPathologyIdToDelete(+row.id);
                                                    setModalConfirmVisible(true);
                                                }}
                                            >
                                                <DeleteOutlineOutlinedIcon sx={styles.delete} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <PathologyForm
                forAdmin
                pathologyData={pathology}
                setUpdateList={setUpdateList}
                modalToggle={setModalFormOpen}
                modalOpen={modalFormOpen}
            />

            <CustomConfirm
                defaultLoading={loading}
                modalVisible={modalConfirmVisible}
                updateModalVisible={setModalConfirmVisible}
                message="Voulez-vous vraiment supprimer cette pathologie ? "
                callBackconfirm={performDeletePathology}
            />
        </Box>
    );
};
