import { ROUTES } from 'src/routes/routes';
import { IsValidStartEndDate } from 'src/utils/helpers/helpers';
import { isEqual } from 'lodash';
import { PROGRAM_STATUS } from 'src/utils/constants/enums';

const newProgramPayload = {
    id: 0,
    title: '',
    icon: undefined,
    attendees: [],
    axis: [],
    level: 0,
    modules: [],
    patients: [],
    profesisonals: [],
    programTypeIds: [],
    startDate: null,
    endDate: null,
    state: 0,
    workshops: [],
    skillIds: [],
    stateId: PROGRAM_STATUS.DRAFT,
};

const programTypeChoices = [
    { name: 'Obésité', id: 1 },
    { name: 'Diabète', id: 2 },
];

const levels = [
    { id: 1, name: 'Suivi' },
    { id: 2, name: 'Initial' },
    { id: 3, name: 'Renforcement' },
];

const isProgramValid = (program: any) =>
    program &&
    program?.level > 0 &&
    program?.skillIds &&
    program?.skillIds.length > 0 &&
    program.title &&
    program?.title.trim().length > 0 &&
    program?.programTypeIds &&
    program?.programTypeIds.length > 0 &&
    IsValidStartEndDate(program.startDate, program.endDate);

const canSaveAsDraft = (program: any) =>
    program &&
    program?.level > 0 ||
    program?.skillIds &&
    program?.skillIds.length > 0 ||
    program.title ||
    program?.title.trim().length > 0 ||
    program?.programTypeIds &&
    program?.programTypeIds.length > 0

const handleCheckValidationPrompt = ({
    pathname,
    program,
    oldprogram,
    setProgramHasChange,
}: // setProgramChangeSaveCallback,
// handleSaveProgram,
any) => {
    const hasChangeMade = !isEqual(program, oldprogram);
    const isNewProgramRoute = pathname === ROUTES.programForm;
    const showPopup = hasChangeMade && isNewProgramRoute;

    if (showPopup) {
        setProgramHasChange(true);
        // setProgramChangeSaveCallback(() => handleSaveProgram);
    } else {
        setProgramHasChange(false);
    }
};

export { handleCheckValidationPrompt, newProgramPayload, programTypeChoices, levels, isProgramValid,canSaveAsDraft };
