import { ButtonBase } from '@mui/material';
import { FC } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';
import { sxStyles } from './styles';
import IconCheckList from './../CustomIcon/IconCheckList';

interface ConclusionItemProps {
    type?: string;
    title?: string;
    onPress?: Function;
}

export const ConclusionItem: FC<ConclusionItemProps> = (props) => {
    const { type, title, onPress } = props;

    return (
        <ButtonBase style={sxStyles.container} onClick={() => onPress?.()}>
            <div style={{ paddingLeft: 20, flex: 1 }}>
                <CustomText
                    fontSize={baseFontSize.sm}
                    style={{ textAlign: 'left' }}
                    color={baseColors.white}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >
                    {title}
                </CustomText>
            </div>

            <div
                style={{
                    ...sxStyles.right,
                    paddingRight: type !== 'APA' ? 8 : 0,
                }}
            >
                <CustomText
                    style={{ marginRight: type !== 'APA' ? 50 : 58 }}
                    color={baseColors.white}
                    fontFamily={baseFontFamily.BreeSerif}
                >
                    {type}
                </CustomText>

                <div style={sxStyles.icon} />

                <IconCheckList height={35} width={35} />
            </div>
        </ButtonBase>
    );
};
