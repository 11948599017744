import { Dispatch, SetStateAction } from 'react';
import { GoalItem, PostGoalPayload } from 'src/interfaces/interfaces';
import { isNotBlank } from 'src/utils/helpers/InputValidator';

const defaultGoalPaylopad = {
    goal: '',
    workshopIds: [],
    knowledgeIds: [],
    articleIds: [],
    skillIds: [],
    duration: 1,
    axisId: 0,
};

function GetIds(items: Array<{ name: string; id: number }>) {
    return items?.map((item) => item.id);
}

const handleChangeOption = ({
    setPayload,
    value,
    isDuration,
}: {
    setPayload: Dispatch<SetStateAction<PostGoalPayload>>;
    value: number;
    isDuration?: boolean;
}) => {
    setPayload((prev: PostGoalPayload) => {
        const workshopIds = new Set(prev.workshopIds);
        const knowledgeIds = new Set(prev.knowledgeIds);

        if (isDuration) {
            let articleIds = new Set(prev.articleIds);

            if (articleIds.has(value)) {
                articleIds.delete(value);
            } else {
                articleIds.add(value);
            }
        }

        return prev;
    });
};

const handleChangeGoalName = ({
    setPayload,
    value,
}: {
    setPayload: Dispatch<SetStateAction<PostGoalPayload>>;
    value: string;
}) => {
    setPayload((prev) => ({
        ...prev,
        goal: value,
    }));
};

const handleChangeGoalType = ({
    setPayload,
    setHideDuration,
    value,
}: {
    setPayload: Dispatch<SetStateAction<PostGoalPayload>>;
    setHideDuration: Dispatch<SetStateAction<boolean>>;
    value: number;
}) => {
    setPayload((prev) => {
        if (value === 2) {
            setHideDuration(true);
            return { ...prev, duration: 1 };
        } else {
            setHideDuration(false);
            return { ...prev, duration: 0 };
        }
    });
};

const checkPayloadState = (payload: PostGoalPayload) => {
    const disabled =
        !Boolean(isNotBlank(payload.goal)) ||
        payload.axisId <= 0 ||
        payload.knowledgeIds?.length === 0 ||
        (payload.workshopIds?.length === 0 && payload.articleIds?.length === 0) ||
        payload.duration <= 0;
    return disabled;
};

const handleSetGoalPayload = ({
    setPayload,
    goal,
}: {
    setPayload: Dispatch<SetStateAction<PostGoalPayload>>;
    goal: GoalItem;
}) => {
    setPayload(() => {
        return {
            goal: goal.goal,
            workshopIds: GetIds(goal.workshops),
            knowledgeIds: GetIds(goal.knowledges),
            articleIds: GetIds(goal.articles),
            skillIds: GetIds(goal.skills),
            duration: goal.duration,
            axisId: goal.axis.id,
            isImpactPatient: 1
        };
    });
};

const handleClickKnowledge =
    ({ setPayload, value }: { setPayload: Dispatch<SetStateAction<PostGoalPayload>>; value: number }) =>
    () => {
        setPayload((prev: PostGoalPayload) => {
            const knowledgeIds = new Set(prev.knowledgeIds);
            if (knowledgeIds?.has(value)) {
                knowledgeIds.delete(value);
                return { ...prev, knowledgeIds: Array.from(knowledgeIds) };
            } else {
                knowledgeIds.add(value);
                return { ...prev, knowledgeIds: Array.from(knowledgeIds) };
            }
        });
    };

const FilterByAxis = ({ data, axisId }: { data: { axis: { id: number } }[]; axisId: number }) => {
    const filtered = data?.filter((item) => item?.axis?.id === axisId) ?? [];
    return axisId > 0 ? filtered : data;
};

export {
    FilterByAxis,
    handleClickKnowledge,
    handleSetGoalPayload,
    defaultGoalPaylopad,
    checkPayloadState,
    handleChangeGoalType,
    handleChangeOption,
    handleChangeGoalName,
};
