import { Box, Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { OptimizedItem, Workshop } from 'src/interfaces/interfaces';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { RelatedGoalModal } from './AddSkillProgramFormsFooter/RelatedGoalModal';

type IProps = {
    payload: Workshop;
    lockWorkshopInput: boolean;
    setPayload: Dispatch<SetStateAction<Workshop>>;
    workshopData: any;
    loadingData?: any;
    isAddFromGoal?: boolean;
    customTitle?: string;
};

export const WorkshopRelatedGoals: FC<IProps> = (props) => {
    const { payload, setPayload, workshopData, isAddFromGoal, customTitle } = props;
    const [goalNames, setGoalNames] = useState<OptimizedItem[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const toggleModal = () => setOpen(!open);

    useEffect(() => {
        if (workshopData?.id) {
            const goalIds = workshopData?.goals?.map((goal: { id: number }) => goal.id);
            setPayload((prev) => ({ ...prev, goalsIds: goalIds }));
            setGoalNames(workshopData?.goals);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workshopData]);

    return (
        <>
            <Grid lg={12} container direction="row" alignItems={'center'} justifyContent="space-between">
                <Grid item>
                    <CustomText>Objectif(s) :</CustomText>
                </Grid>

                <Grid item style={{ width: '60%' }}>
                    {goalNames?.length > 0 ? (
                        <Box
                            style={{
                                cursor: 'pointer',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                backgroundColor: baseColors.green.light,
                                padding: 12,
                                borderRadius: 10,
                            }}
                            onClick={toggleModal}
                        >
                            {goalNames?.map((goal) => (
                                <CustomText
                                    key={goal.id}
                                    style={{
                                        paddingRight: 20,
                                        marginBottom: 7,
                                    }}
                                    fontSize={baseFontSize.mini}
                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                >
                                    {goal.name}
                                </CustomText>
                            ))}
                        </Box>
                    ) : (
                        <Grid
                            onClick={toggleModal}
                            item
                            style={{
                                cursor: 'pointer',
                                backgroundColor: baseColors.green.light,
                                padding: 12,
                                width: '100%',
                                borderRadius: 10,
                            }}
                        >
                            <CustomText
                                style={{}}
                                color={baseColors.green.primary}
                                fontSize={baseFontSize.mini}
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                            >
                                Choisir le(s) objectif(s) liés à l'atelier
                            </CustomText>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <RelatedGoalModal
                setGoalNames={setGoalNames}
                payload={payload}
                setPayload={setPayload}
                open={open}
                toggleModal={toggleModal}
                isAddFromGoal={isAddFromGoal}
                customTitle={customTitle}
            />

            <Spacer />
        </>
    );
};
