import { Dispatch, FC, SetStateAction } from 'react';
import { Box } from '@mui/material';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { PostSkillPayload, ProgramSkill, SkillItem } from 'src/interfaces/interfaces';
import { sxStyles } from '../styles';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import useSkillHooks from 'src/hooks/useSkillHooks';
import { defaultSkillPaylopad } from '../Skills.utils';


type IProps = {
    skill: SkillItem;
    setSkill: Dispatch<SetStateAction<SkillItem>>;
    setPayload: Dispatch<SetStateAction<PostSkillPayload>>;
    payload: PostSkillPayload;
    toggleModal: any;
    setUpdateList?: Dispatch<SetStateAction<boolean>>;
    setJustAddedSkills?: Dispatch<SetStateAction<ProgramSkill[]>>;
    setNewSkillLabel?: Dispatch<SetStateAction<string>>;
};

export const SkillFormFooter: FC<IProps> = (props) => {
    const { setNewSkillLabel, setJustAddedSkills, setSkill, payload, toggleModal, setPayload, setUpdateList, skill } =
        props;
    const { saving, handleCreateNewSkill, handleEditSkill } = useSkillHooks({
        toggleModal,
        setPayload,
        setUpdateList,
        setSkill,
        setJustAddedSkills,
        setNewSkillLabel,
    });

    const canSubmit = !Boolean(isNotBlank(payload.skill)) || payload.goalIds?.length === 0;
    const handleClose = () => {
        setPayload(defaultSkillPaylopad);
        toggleModal();
    };

    const handleClick = () => {
        if (skill?.id > 0) {
            handleEditSkill({ skillId: skill.id, payload });
        } else {
            handleCreateNewSkill({ payload });
        }
    };

    return (
        <Box style={sxStyles.footer}>
            <CustomLoadingButton
                outlined
                disabled={saving}
                width="100%"
                label="Annuler"
                handleClick={handleClose}
                style={{ flex: 0.95 / 2 }}
            />
            <CustomLoadingButton
                loading={saving}
                disabled={saving || canSubmit}
                width="100%"
                label={'Enregistrer'}
                handleClick={handleClick}
                style={{ flex: 0.95 / 2 }}
            />
        </Box>
    );
};
