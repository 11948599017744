import { FC, useContext, useEffect, useState } from 'react';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { PatientService } from 'src/services/Patients';
import { BreeSerifText } from 'src/components/Text/BreeSerifText';
import { PatientContext } from 'src/providers/PatientProvider';
import EvaluationGoalItem from './EvaluationGoalItem';
import { Spacer } from 'src/components/Spacer/Spacer';
import { baseColors } from 'src/utils/constants/baseStyles';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { useFetchPatientPrograms } from 'src/hooks/ReactQueryHooks/useProgramRQHooks';

interface EvaluationObjectifsProps {}

const EvaluationObjectifs: FC<EvaluationObjectifsProps> = (props) => {
    const [goals, setGoals] = useState<any[]>([]);
    const [doneProgramGoals, setDoneProgramGoals] = useState<any[]>([]);
    const [fetchingGoals, setFetchingGoals] = useState<boolean>(false);
    const { selectedPatient } = useContext(PatientContext);
    const [updating, setUpdating] = useState(false);
    const patientId = selectedPatient?.id ?? 0;

    const fetchPatientGoal = () => {
        setFetchingGoals(true);
        PatientService.getGoalPatients(patientId)
            .then((response) => {
                const data = response?.data?.data;
                const allGoals = data?.skills?.flatMap((skill: any) => skill?.goals);
                const optimized = Array.from(allGoals ?? [], (goal: any) => ({
                    ...goal,
                    programId: goal?.program?.id,
                }));

                if (optimized.length > 0) {
                    const filtered = optimized?.filter(
                        (goal: any) => goal.state?.id === 2 || goal.state.name === 'Terminé',
                    );
                    setDoneProgramGoals(filtered);
                }

                setGoals(optimized ?? []);
                setFetchingGoals(false);
            })
            .catch((err) => {
                setFetchingGoals(false);
            });
    };

    useEffect(() => {
        if (patientId > 0) {
            fetchPatientGoal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, updating]);

    const isEmpty = goals?.length === 0;

    const Loading = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CustomCircleLoader height={50} width={50} />
        </div>
    );

    const doneGoals = goals.filter(
        (item) => item?.state?.name === 'Terminé' || item?.numValidated === item?.numTimeValidation,
    );
    const doneGoalIds = doneGoals?.map((goal) => goal.id);

    const { data: userPrograms, isLoading: fetchingUserPrograms } = useFetchPatientPrograms({
        patientId: patientId ?? 0,
    });

    const doneProgram = userPrograms?.filter((programItem: any) => programItem?.program?.state?.code === 'TERMINE');
    const doneProgramIds = doneProgram?.map((programItem: any) => programItem?.program?.id);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1.2 / 2,
                padding: 10,
                paddingRight: 50,
                backgroundColor: baseColors.green.light,
                borderRadius: 5,
                height: 'calc(100vh - 370px)',
                overflowY: 'scroll',
            }}
        >
            <BreeSerifText style={{ textAlign: 'center', fontSize: 16, marginBottom: 10 }}>
                Liste des objectifs du patient
            </BreeSerifText>

            <Spacer />

            <>
                {fetchingGoals || fetchingUserPrograms ? (
                    Loading
                ) : isEmpty ? (
                    <CustomEmptyData marginTop={100} height={60} width={70} />
                ) : (
                    <div>
                        {goals?.map((goal) => (
                            <EvaluationGoalItem
                                doneProgramGoals={doneProgramGoals}
                                doneProgramIds={doneProgramIds}
                                doneGoalIds={doneGoalIds}
                                goal={goal}
                                setUpdating={setUpdating}
                            />
                        ))}
                    </div>
                )}
            </>
        </div>
    );
};

export default EvaluationObjectifs;
