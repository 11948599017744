import { Divider, Grid } from '@mui/material';
import { FC, useContext,useState } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import PdfIcon from 'src/components/CustomIcon/IconPdf';
import { ProgramContext } from 'src/providers/ProgramProvider';
import { PatientProgram, ProgramPatientWorkshop } from 'src/interfaces/interfaces';
import WorkshopItem from './WorkshopItem/WorkshopItem';
import { StateProgramUserTemp } from "src/utils/constants/constants";
import { PatientContext } from 'src/providers/PatientProvider';
import usePrograms from 'src/hooks/usePrograms';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

interface ProgramItemInterface {
    programData: PatientProgram;
    checkedProgramId: number;
    setCheckedProgramId: Function;
}

const ProgramItem: FC<ProgramItemInterface> = (props) => {
    const { programData, setCheckedProgramId } = props;
    const { checkedProgram, setCheckedProgram, setCheckedWorkshop } = useContext(ProgramContext);
    const [selectedWorkshop, setSelectedWorkshop] = useState<any>(null);
    const programWorkshops = programData?.programDetails?.workshop;
    const { selectedPatient } = useContext(PatientContext);
    const patientId: number = selectedPatient?.id ?? 0;

    const {fetchingPdfFile, downloadProgramPdf } = usePrograms({});

    const handleSetDefaultSelectedItem = () => {
        if (programWorkshops?.length! > 0) {
            setCheckedWorkshop(programWorkshops?.[0]);
        } else {
        }
    };

    const handleSetProgram = () => {
        setCheckedProgramId(programData?.id);
        setCheckedProgram(programData);
        setCheckedWorkshop(programData?.programDetails?.workshop ?? []);
    };

    const handleCheckProgram = () => {
        handleSetProgram();
        //handleSetDefaultSelectedItem();
    };

    const selectedProgram = checkedProgram?.program?.id === programData?.program?.id;
    const stateProgramUserTemp = programData?.stateProgramUserTemp ?? '';

    return (
        <Grid style={{ padding: 5 }} container direction={'column'} spacing={1}>
            <Grid item onClick={handleCheckProgram}>
                <CustomText
                    style={{ cursor: 'pointer' }}
                    fontSize={12}
                    fontFamily={selectedProgram ? baseFontFamily.OpenSansBold : baseFontFamily.OpenSansSemiBold}
                >
                    {programData?.title}
                </CustomText>
            </Grid>

            <Grid item>
                <Grid container direction={'row'} style={{cursor:'pointer'}} onClick={() => downloadProgramPdf(patientId, programData?.id)}>
                    <Grid item>
                        <CustomText
                            style={{ marginRight: 10 }}
                            fontSize={13}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            color={programData?.state?.id !== 1 ? baseColors.green.primary : baseColors.orange.primary}
                        >
                            {StateProgramUserTemp?.[stateProgramUserTemp]?.title}
                        </CustomText>
                    </Grid>
                    {stateProgramUserTemp === StateProgramUserTemp.TERMINE.code && (
                        <>
                        {fetchingPdfFile ?
                            (<Grid item style={{paddingTop:'5px'}}>
                                <CustomCircleLoader height={10} width={10}  />
                            </Grid>)
                            :
                            (<Grid item>
                                <PdfIcon />
                            </Grid>)
                        }
                        </>
                    )}
                </Grid>

                <Grid marginTop={1} marginLeft={2}>
                    {programWorkshops?.map((workshopItem: ProgramPatientWorkshop) => (
                        <WorkshopItem
                            key={workshopItem.id}
                            workshopData={workshopItem}
                            handleSetProgram={handleSetProgram}
                        />
                    ))}
                </Grid>
            </Grid>

            <Divider
                style={{
                    marginTop: 10,
                    marginLeft: 10,
                    marginBottom: 15,
                    background: baseColors.green.primary,
                    height: 1,
                }}
            />
        </Grid>
    );
};

export default ProgramItem;
