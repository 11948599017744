import { FC, useEffect, useState, useContext } from 'react';
import useStyles from './styles';
import { Box } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { Spacer } from 'src/components/Spacer/Spacer';
import { ProgramFormHeader } from './Header/ProgramFormHeader';
import { InputField } from 'src/components/InputField/InputField';
import CustomDatePicker from 'src/components/CustomDatePicker';
import { ProgramItem } from 'src/interfaces/interfaces';
import { IsToday, IsValidStartEndDate } from 'src/utils/helpers/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { dbDateFormat } from 'src/utils/utils';
import moment from 'moment';
import { handleCheckValidationPrompt, levels, newProgramPayload } from './Logic';
import { ProgramContext } from 'src/providers/ProgramProvider';
import useAvoid404onPageRefresh from 'src/hooks/useAvoid404onPageRefresh';
import { ProgramFormFooter } from './ProgramFormFooter';
import { ProgramSkillsForm } from './ProgramSkillsForm';
import { ProgramPathologyForm } from './ProgramPathologyForm';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { searchElementByName, sortByIds } from 'src/utils/utils';

interface LocationState {
    program: any;
}

export const ProgramForm: FC = (props) => {
    const classes = useStyles();
    const { state } = useLocation<LocationState>();
    const defaultProgramItem = {
        ...newProgramPayload,
        id: state?.program?.id ?? 0,
    };
    const { setProgramHasChange, setProgramChangeSaveCallback } = useContext(ProgramContext);
    const history = useHistory();
    const location: any = useLocation();
    const [program, setProgram] = useState<ProgramItem>(defaultProgramItem);
    const oldprogram = defaultProgramItem;
    const [rerender, setRerender] = useState(false);
    const [showLeaveConfirm,setShowLeaveConfirm] = useState<boolean>(false);
    const [targetLocation,setTargetLocation] = useState<string>("");
    const [addeProgramIds,setAddeProgramIds] = useState<number[]>([]);

    useAvoid404onPageRefresh({ history, location });

    useEffect(() => {
        if (state?.program) {
            let defaultModules = state?.program?.modules?.map((item: any) => {
                let id = item.id;
                let workshopIds = item?.workshops.map((itm: any) => itm.id);
                return { id: id, workshopIds: workshopIds };
            });

            let defaultAttendees = state.program?.attendees?.map((item: any) => {
                return {
                    id: item?.user?.id,
                    type: item?.type?.id,
                    isDeleted: item?.isDeleted,
                };
            });

            let defaultWorkshops = state?.program?.workshops?.map((item: any) => item.id);

            setProgram((old) => ({
                ...old,
                id: state?.program?.id,
                title: state?.program?.title,
                startDate: moment(state?.program?.startDate, dbDateFormat).toDate(),
                endDate: moment(state?.program?.endDate, dbDateFormat).toDate(),
                programTypeIds: state?.program?.programType?.map((item: any) => item?.id),
                state: state?.program?.state?.id,
                level: state?.program?.level?.id,
                modules: defaultModules,
                workshops: defaultWorkshops,
                attendees: defaultAttendees ? defaultAttendees.filter((item: any) => item.type === 2) : [],
                patients: defaultAttendees ? defaultAttendees.filter((item: any) => item.type === 1) : [],
                skillIds: state?.program?.skills?.map((item: any) => item?.id),
                icon:state?.program?.icon
            }));
        }
    }, [state?.program]);

    const {
        location: { pathname },
    } = history;

    useEffect(() => {
        handleCheckValidationPrompt({
            pathname,
            program,
            oldprogram,
            setProgramHasChange,
            setProgramChangeSaveCallback,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [program, oldprogram, pathname]);

    const newProgram = program?.id === 0;
    const title = newProgram ? 'Créer mon programme' : 'Modifier mon programme';

    const inputLabel = (label: string, minWidth?: number) => (
        <CustomText
            fontSize={baseFontSize.mini}
            fontFamily={baseFontFamily.OpenSansSemiBold}
            style={{ marginRight: 5, minWidth: minWidth ?? 150 }}
        >
            {label} :
        </CustomText>
    );

    return (
        <>
            <Box className={classes.container}>
                <CustomText style={{ textAlign: 'center' }} isBreefSerif isTitle>
                    {title}
                </CustomText>

                <ProgramFormHeader
                    rerender={rerender}
                    program={program}
                    setProgram={setProgram}
                    icon={program.icon}
                    level={levels}
                    setShowLeaveConfirm={setShowLeaveConfirm}
                    setTargetLocation={setTargetLocation}
                />

                <div className={classes.wrapper}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingLeft: '15%',
                        }}
                    >
                        <div style={{ flex: 2 / 4, alignItems: 'center' }}>
                            <div className={classes.programNameForm}>
                                <div className={classes.inlineFormItem}>
                                    {inputLabel('Nom du programme')}
                                    <div style={{ marginLeft: 10 }}>
                                        <InputField
                                            label=""
                                            single
                                            errorMessage="Nom Obligatoire"
                                            placeholder="Taper le nom du programme"
                                            value={program.title}
                                            width={240}
                                            onChange={(value: any) => {
                                                setProgram((old) => ({ ...old, title: value }));
                                            }}
                                            idSuffix="nom-programme"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Spacer height={30} />

                        <div style={{ flex: 2 / 4, justifyContent: 'flex-end' }}>
                            <div className={classes.inlineForm}>
                                <div className={classes.inlineFormItem}>
                                    {inputLabel('Date de début')}
                                    <CustomDatePicker
                                        likeSelect
                                        width={200}
                                        setValue={(date: Date) => {
                                            setProgram((old) => ({ ...old, startDate: date }));
                                        }}
                                        value={program.startDate}
                                        idpicker="date-debut-programme"
                                    />
                                </div>
                            </div>

                            <div className={classes.inlineForm}>
                                <div className={classes.inlineFormItem}>
                                    {inputLabel('Date Fin')}
                                    <CustomDatePicker
                                        likeSelect
                                        width={200}
                                        setValue={(date: Date) => setProgram((old) => ({ ...old, endDate: date }))}
                                        value={program.endDate}
                                        idpicker="date-fin-programme"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Spacer height={30} />
                    <div style={{display:"flex",flexDirection:"row",gap:"50px",justifyContent:"center", margin: "0 auto"}}>
                        <div>
                            <ProgramPathologyForm program={program} setProgram={setProgram} />
                        </div>
                        <div>
                            <ProgramSkillsForm program={program} setProgram={setProgram} />
                        </div>
                    </div>
                </div>

                <Spacer height={5} />

                <ProgramFormFooter
                    program={program}
                    setProgram={setProgram}
                    rerender={rerender}
                    setRerender={setRerender}
                    defaultProgramItem={defaultProgramItem}
                    showLeaveConfirm={showLeaveConfirm}
                    setShowLeaveConfirm={setShowLeaveConfirm}
                    targetLocation={targetLocation}
                    setTargetLocation={setTargetLocation}
                />

                {(!IsToday(program?.startDate) || !IsToday(program?.endDate)) &&
                    !IsValidStartEndDate(program.startDate, program.endDate) && (
                        <CustomText style={{ textAlign: 'center', marginTop: 20 }} color={baseColors.orange.primary}>
                            La date de fin ne doit pas être avant la date de début
                        </CustomText>
                    )}
            </Box>
        </>
    );
};
