import Wrapper from '../components/Wrapper';
import DashBoard from '../views/DashBoard';
import Home from '..//views/Home';
import MyAgenda from '..//views/MyAgenda';
import Notification from '..//views/Notification';
import Patients from '..//views/Patients';
import Programme from '..//views/Programme';
import { NotFound } from 'src/views/Error/NotFound';
import { ProgramSynthese } from 'src/views/Programme/Synthese/ProgramSynthese';
import { ProgramDetail } from 'src/views/Programme/Detail/ProgramDetail';
import SecurityManagement from 'src/views/Security/SecurityManagement/SecurityManagement';
import SettingsManagement from 'src/views/Settings/SettingsManagement/SettingsManagement';
import { UserForm } from 'src/views/Settings/Users/Form/UserForm';
import InitialPageLoader from 'src/components/CustomLoader/InitialPageLoader';
import { ProgramForm } from 'src/views/Programme/Form/ProgramForm';
import UserActivation from './../views/Login/UserActivation';
import { SALUTANCE } from 'src/utils/constants/constants';

export const ProgramRoutes = {
    programmes: '/programmes',
    programForm: '/programmes/new',
    programDetails: '/programmes/:id/:detail/:subDetail',
    programSyntheseDetails: '/programmes/:id/:detail',
};

export const SALUTANCE_ROUTE = {
    Exports: '/settings/export'
};

export const ROUTES = {
    login: '/login',
    loginCpts: '/loginCpts',
    signInCpts: '/signInCpts',
    passwordForgot: '/password-forgot',
    mailVerification: '/mailVerification',
    passwordReset: '/password-reset',
    userActivationCpts: '/user/activation',
    home: '/',
    notifications: '/notifications',
    loadingInitialPage: '/loadingInitialPage',
    dashboard: '/dashboard',
    agenda: '/agenda',
    patientsAdd: '/patients/add',
    patientsEdit: '/patients/:id/edit',
    patientsImport: '/patients/import',
    patientsDetails: '/patients/list/:id/:page',
    patientsSubDetails: '/patients/list/:id/:page/:page',
    ...ProgramRoutes,
    adminSecurity: '/admin/security',
    security: '/admin/security/roles',
    roles: '/admin/security/roles',
    features: '/admin/security/features',
    permissions: '/admin/security/permissions',
    authorizations: '/admin/security/authorizations',
    connexions: '/admin/security/connexions',
    user_history: '/admin/security/user_history',
    settings: '/settings',
    users: '/settings/users',
    modules: '/settings/modules',
    specialities: '/settings/specialities',
    workshops: '/settings/workshops',
    agendaSettings: '/settings/agenda',
    quizSettings: '/settings/quiz',
    generalSettings: '/settings/general',
    contactSettings: '/settings/contact',
    articleSettings: '/settings/article',
    pathologySettings: '/settings/pathology',
    ETFSettings: '/settings/ETFList',
    ThemeSettings: '/settings/theme',
    notFound: '/404',
    DESettings: '/settings/diagnostic',
    SkillSettings: '/settings/skills',
    GoalSettings: '/settings/goals',
    userForm: '/users/new',
    ...SALUTANCE_ROUTE,
};

const SalutanceWrappedComponents = [
    {
        key: 'EXPORT_SETTING',
        path: ROUTES.Exports,
        component: Wrapper(SettingsManagement),
    },
];

export const wrappedComponent = [
    {
        key: 'HOME',
        path: ROUTES.home,
        component: Wrapper(Home),
    },
    {
        key: 'DASHBOARD',
        path: ROUTES.dashboard,
        component: Wrapper(DashBoard),
    },
    {
        key: 'MY_AGENDA',
        path: ROUTES.agenda,
        component: Wrapper(MyAgenda),
    },
    {
        key: 'PATIENTS_DETAILS',
        path: ROUTES.patientsDetails,
        component: Wrapper(Patients),
    },
    {
        key: 'PATIENTS_SUB_DETAILS',
        path: ROUTES.patientsSubDetails,
        component: Wrapper(Patients),
    },
    {
        key: 'PATIENTS_ADD',
        path: ROUTES.patientsAdd,
        component: Wrapper(Patients),
    },
    {
        key: 'ACTIVATIONUSER',
        path: ROUTES.userActivationCpts,
        component: Wrapper(UserActivation),
    },
    {
        key: 'PATIENTS_EDIT',
        path: ROUTES.patientsEdit,
        component: Wrapper(Patients),
    },
    {
        key: 'DE_SETTINGS',
        path: ROUTES.DESettings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'SKILLS_SETTINGS',
        path: ROUTES.SkillSettings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'GOALS_SETTINGS',
        path: ROUTES.GoalSettings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'NOT_FOUND',
        path: ROUTES.notFound,
        component: Wrapper(NotFound),
    },
    {
        key: 'PATIENTS_IMPORT',
        path: ROUTES.patientsImport,
        component: Wrapper(Patients),
    },
    {
        key: 'PROGRAMMES',
        path: ROUTES.programmes,
        component: Wrapper(Programme),
    },

    {
        key: 'PROGRAMSYNTHESEDETAIL',
        path: ROUTES.programSyntheseDetails,
        component: Wrapper(ProgramSynthese),
    },
    {
        key: 'CONNEXION',
        path: ROUTES.connexions,
        component: Wrapper(SecurityManagement),
    },
    {
        key: 'PROGRAMDETAIL',
        path: ROUTES.programDetails,
        component: Wrapper(ProgramDetail),
    },
    {
        key: 'PROGRAMFORM',
        path: ROUTES.programForm,
        component: Wrapper(ProgramForm),
    },
    {
        key: 'NOTIFICATIONS',
        path: ROUTES.notifications,
        component: Wrapper(Notification),
    },
    {
        key: 'SECURITY',
        path: ROUTES.security,
        component: Wrapper(SecurityManagement),
    },
    {
        key: 'ROLES',
        path: ROUTES.roles,
        component: Wrapper(SecurityManagement),
    },
    {
        key: 'FEATURES',
        path: ROUTES.features,
        component: Wrapper(SecurityManagement),
    },
    {
        key: 'PERMISSIONS',
        path: ROUTES.permissions,
        component: Wrapper(SecurityManagement),
    },
    {
        key: 'AUTHORIZATIONS',
        path: ROUTES.authorizations,
        component: Wrapper(SecurityManagement),
    },
    {
        key: 'USER_HISORY',
        path: ROUTES.user_history,
        component: Wrapper(SecurityManagement),
    },
    {
        key: 'SETTINGS',
        path: ROUTES.settings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'SPECIALITIES',
        path: ROUTES.specialities,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'USERS',
        path: ROUTES.users,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'MODULES',
        path: ROUTES.modules,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'WORKSHOPS',
        path: ROUTES.workshops,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'AGENDA_SETTINGS',
        path: ROUTES.agendaSettings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'QUIZ_SETTINGS',
        path: ROUTES.quizSettings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'USERFORM',
        path: ROUTES.userForm,
        component: Wrapper(UserForm),
    },
    {
        key: 'GENERAL_SETTINGS',
        path: ROUTES.generalSettings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'CONTACTE_SETTINGS',
        path: ROUTES.contactSettings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'ARTICLE_SETTINGS',
        path: ROUTES.articleSettings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'PATHOLOGY_SETTINGS',
        path: ROUTES.pathologySettings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'ETF_SETTINGS',
        path: ROUTES.ETFSettings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'THEME_SETTINGS',
        path: ROUTES.ThemeSettings,
        component: Wrapper(SettingsManagement),
    },
    {
        key: 'LOADING_INITIAL_PAGE',
        path: ROUTES.loadingInitialPage,
        component: Wrapper(InitialPageLoader),
    },
    ...SalutanceWrappedComponents
];

