import { Grid } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import CustomDatePicker from 'src/components/CustomDatePicker';
import { transformeTimeFormat } from 'src/components/CustomPlanningAgendaTimeLine/PlanningContentLogic';
import CustomTimePicker from 'src/components/CustomTimePicker';
import { setDefaultTime } from 'src/components/PatientOfDayCard/EventLogic';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { EventHoursProps } from 'src/interfaces/interfaces';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { eventDateTimeFormat, stringDateToZeroMinute, timeWithoutTimeZone } from 'src/utils/utils';
import {
    addFifteenMinutes,
    isTwoTimeEqual,
    manageEventEndingTime,
    monthlyDateValue,
    setTimeMinutes,
} from '../EventModalLogic';
import { EventContext } from 'src/providers/EventProvider';
import { manageEventEndingTimeParams } from 'src/interfaces/types';
import { toast } from 'react-toastify';
import { baseColors } from 'src/utils/constants/baseStyles';

const EventHours: FC<EventHoursProps> = (props) => {
    const {
        eventPayload,
        setEventPayload,
        eventData,
        editEventData,
        createEventFromBtnAction,
        viewType,
        dataForWeeklyEvent,
        editFromDnd,
        newEventDate,
        newEventEndDate,
        message,
        eventDate,
        setEventDate,
        valideStartTime,
        valideEndTime,
        droppedEventId,
        droppedEventDateTime,
        selectedWorkshopData
    } = props;
    const { currentDate } = useContext(AgendaContext);
    // const { selectedSWorkshopSession } = useContext(EventContext);
    const [counter, setCounter] = useState(0);
    const [showInfoStartAt, setShowInfoStartAt] = useState(false);
    const [showInfoEndAt, setShowInfoEndAt] = useState(false);

    const isCreneau = eventPayload?.typeId === 9;

    const monthlyDate: any = monthlyDateValue({
        editEventData,
        newEventDate,
        currentDate,
        createEventFromBtnAction,
    });

    const weeklyDate: any = new Date(
        createEventFromBtnAction ? currentDate : dataForWeeklyEvent?.weeklyEventData?.dayDate,
    );

    const dateToUse =
        droppedEventId && droppedEventId > 0
            ? droppedEventDateTime?.start
            : viewType === 'monthly'
            ? monthlyDate
            : viewType === 'weekly' && dataForWeeklyEvent
            ? weeklyDate
            : editEventData?.startDate
            ? new Date(editEventData?.startDate)
            : newEventDate || currentDate;

    let defaultStart: Date = new Date(dateToUse);
    let defaultEnd: Date = new Date(dateToUse);

    if (editEventData && !editFromDnd) {
        if (editEventData.startDate) {
            if(isCreneau) editEventData.startDate = stringDateToZeroMinute(editEventData.startDate);
            setDefaultTime(transformeTimeFormat(timeWithoutTimeZone(editEventData.startDate)), defaultStart);
        }
        if (editEventData?.endDate) {
            if(isCreneau) editEventData.endDate = stringDateToZeroMinute(editEventData.endDate);
            setDefaultTime(transformeTimeFormat(timeWithoutTimeZone(editEventData?.endDate)), defaultEnd);
        }
    }

    if (eventData && !editFromDnd) {
        if (eventData?.timeStart) {
            setDefaultTime(eventData?.timeStart, defaultStart);
        }
        if (eventData?.timeEnd) {
            setDefaultTime(eventData?.timeEnd, defaultEnd);
        }
    }

    if (createEventFromBtnAction) {
        const today = new Date();
        const dateToUseForNewEvent = new Date(dateToUse);
        const currentHours = today.getHours();
        const currentMinutes = today.getMinutes();
        dateToUseForNewEvent.setHours(currentHours);
        dateToUseForNewEvent.setMinutes(currentMinutes);
        defaultStart = dateToUseForNewEvent;
        defaultEnd = dateToUseForNewEvent;
    }

    let timeToUse = {
        timeStart: createEventFromBtnAction ? addFifteenMinutes(defaultStart) : defaultStart,
        timeEnd: createEventFromBtnAction ? setTimeMinutes(defaultStart, defaultEnd) : defaultEnd,
    };

    if (isTwoTimeEqual(timeToUse?.timeStart, String(timeToUse?.timeEnd))) {
        timeToUse.timeEnd = setTimeMinutes(defaultStart, defaultEnd);
    }

    const [startAt, setStartAt] = useState<any>(timeToUse?.timeStart);
    const [endAt, setEndAt] = useState<any>(timeToUse?.timeEnd);
    const workShopDuration = Number(selectedWorkshopData?.duration?.split(":")?.join("."))

    useEffect(() => {
        let timer = () =>
            setTimeout(() => {
                setEventDate(dateToUse);
            }, 700);

        if (dateToUse && counter === 0) {
            timer();
            setCounter((prev) => prev + 1);
        }

        return () => {
            clearTimeout(timer());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateToUse, counter]);

    useEffect(() => {
        setEventPayload((prev: any) => ({
            ...prev,
            startDate: eventDateTimeFormat(eventDate, startAt, true),
            endDate: eventDateTimeFormat(eventDate, new Date(endAt), true),
        }));
    }, [eventData,endAt,startAt]);

    useEffect(() => {
        if (createEventFromBtnAction || eventData) {
            setEndAt(
                manageEventEndingTime({
                    dateTime: startAt,
                    eventTypeId: eventPayload?.typeId ?? 0,
                }),
            );
        }
    }, [eventPayload?.typeId, createEventFromBtnAction, eventData,startAt]);

    useEffect(() => {
        if (!eventData) {
            const manageEventEndingTimeParams : manageEventEndingTimeParams =  {
                dateTime: startAt,
                eventTypeId: eventPayload?.typeId ?? 0,
                duration: 0
            }

            if(eventPayload?.typeId === 1 && workShopDuration)
                manageEventEndingTimeParams.duration = workShopDuration
            
            setEndAt(
                manageEventEndingTime(manageEventEndingTimeParams),
            );
        }

        if(editEventData){
            const manageEventEndingTimeParams =  {
                dateTime: startAt,
                eventTypeId: editEventData?.typeId ?? 0,
                duration:0
            }

            manageEventEndingTimeParams.duration = editEventData?.duration;
            setEndAt(
                manageEventEndingTime(manageEventEndingTimeParams),
            );
        }
    }, [eventPayload?.typeId, eventData, workShopDuration]);

    const handleChange = (type: 'start' | 'end' | 'date', date: Date | null) => {
        let value = date;

        if(isCreneau && date && date.getMinutes() !== 0){
            type === 'end' && setShowInfoEndAt(true);
            type === 'start' && setShowInfoStartAt(true);
            setTimeout(() => {
                type === 'end' && setShowInfoEndAt(false);
                type === 'start' && setShowInfoStartAt(false);
            }, 2500);
            date.setMinutes(0);
            value = new Date(date);
        }
        
        const formatedDate = value && eventDateTimeFormat(eventDate, value, true);
        const autoAddOneHourToEndHour = manageEventEndingTime({
            dateTime: formatedDate,
            eventTypeId: eventPayload?.typeId ?? 0,
        });

        if (type === 'start') {
            setEventPayload((prev: any) => ({
                ...prev,
                startDate: formatedDate,
                endDate: autoAddOneHourToEndHour,
                checkStartDate: value,
            }));
            setEndAt(autoAddOneHourToEndHour);
        }

        if (type === 'end') {
            setEventPayload((prev: any) => ({ ...prev, endDate: formatedDate }));
        }
    };

    const handleChangeEventDate = (date: Date) => setEventDate(date);

    useEffect(() => {}, [eventDate, currentDate]);

    useEffect(() => {
        if (droppedEventId && droppedEventId > 0) {
            const start = droppedEventDateTime?.start;
            const end = droppedEventDateTime?.end;
            setStartAt(start);
            setEndAt(end);
            setEventPayload((prev: any) => ({
                ...prev,
                startDate: start,
                endDate: end,
            }));
        }
    }, [droppedEventId]);

    useEffect(() => {
        if(isCreneau){
            const startAtCreneau = new Date(startAt);
            const endAtCreneau = new Date(endAt);
            setStartAt(startAtCreneau.setMinutes(0))
            setEndAt(endAtCreneau.setMinutes(0))

            setEventPayload((prev: any) => ({
                ...prev,
                startDate: eventDateTimeFormat(eventDate, startAtCreneau, true),
                endDate: eventDateTimeFormat(eventDate, endAtCreneau, true),
            }));
        }
    }, [eventPayload.startDate,eventPayload.endDate,isCreneau])

    const fullHourInfo = 'Veuillez sélectionner une heure complète';

    return (
        <>
            <Grid container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText>Date de l'événement :</CustomText>
                </Grid>
                <Grid item width={150} marginLeft={12} height={50}>
                    <div style={{ marginLeft: -11 }}>
                        <CustomDatePicker
                            disablePast
                            likeSelect
                            width="100%"
                            validator={true}
                            value={eventDate}
                            setValue={(date: Date) => {
                                handleChangeEventDate(date);
                            }}
                            idpicker="date-event"
                        />
                    </div>
                </Grid>
            </Grid>

            <Spacer />

            <Grid container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText>Heure du début :</CustomText>
                    {message}
                </Grid>
                <Grid item width={300} marginLeft={16} height={50}>
                    <div style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}>
                        <div style={{ marginLeft: -11, width:'53%' }}>
                            <CustomTimePicker
                                type="start"
                                value={startAt}
                                setValue={setStartAt}
                                validator={valideStartTime}
                                handleChange={handleChange}
                                idtime="heure-debut-event"
                            />
                        </div>
                        {
                            showInfoStartAt && 
                            <div>
                                <CustomText color={baseColors.orange.primary}>{fullHourInfo}</CustomText>
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>

            <Spacer />

            <Grid container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText>Heure de fin :</CustomText>
                </Grid>
                <Grid item width={300} marginLeft={19} height={50}>
                    <div style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}>
                        <div style={{ marginLeft: -11,width:'53%' }}>
                            <CustomTimePicker
                                type="end"
                                value={endAt}
                                setValue={setEndAt}
                                validator={valideEndTime}
                                handleChange={handleChange}
                                idtime="heure-fin-event"
                            />
                        </div>
                        {
                            showInfoEndAt && 
                            <div>
                                <CustomText color={baseColors.orange.primary}>{fullHourInfo}</CustomText>
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>

            <Spacer />
        </>
    );
};

export default EventHours;
