import { Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { Patient, PatientRegistrationPayload } from 'src/interfaces/interfaces';
import { isNotBlank, isValidMail, isValidPostalCode } from 'src/utils/helpers/InputValidator';
import { PatientService } from 'src/services/Patients';
import { message } from 'src/utils/helpers/helpers';
import { useHistory } from 'react-router-dom';
import { PatientContext } from 'src/providers/PatientProvider';
import { toast } from 'react-toastify';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { OperationMessage } from 'src/utils/constants/constants';
import MailJetableService from 'src/services/MailJetable/mailJetable.service';
import { ROUTES } from 'src/routes/routes';
import { defaultPatientPayload } from './PatientsUtils';
import useEducationalDiagnosis from 'src/hooks/useEducationalDiagnosis';
import { useFetchPatientRQHooks } from 'src/hooks/ReactQueryHooks/usePatientRQHooks';

interface PatientFormProps {
    setLoackInput: any;
    setPostTraitementMessage: any;
    setModalPostTraitementVisible: any;
    setLoading: any;
    payload: PatientRegistrationPayload;
    setPayload: Dispatch<SetStateAction<PatientRegistrationPayload>>;
    setUpdate?: Dispatch<SetStateAction<boolean>>;
    medecinTraitantId: number;
}

export const PatientFormBtn: FC<PatientFormProps> = ({
    setLoackInput,
    payload,
    setPayload,
    setUpdate,
    medecinTraitantId,
}) => {
    const [addPatientLoading, setAddPatientLoading] = useState(false);
    const history = useHistory();
    const { setSelectedPatient, setUpdateList, setJustAddedPatient, setActiveLoadingIndicator } =
        useContext(PatientContext);

    const { refetch: refetchPatients } = useFetchPatientRQHooks({ dateFilter: '' });

    const { fetchEducationalDiagnosis } = useEducationalDiagnosis({
        patientId: payload?.id ?? 0,
    });

    useEffect(() => {
        if (payload && payload.id && payload?.id > 0) {
            fetchEducationalDiagnosis();
        }
    }, [payload]);

    const validPatientData = () => {
        return (
            isNotBlank(payload?.address) &&
            isNotBlank(payload?.firstName) &&
            isNotBlank(payload?.lastName) &&
            isNotBlank(payload?.profession) &&
            isValidMail(payload?.email) &&
            isValidPostalCode(payload?.postalCode)
        );
    };

    const backProfil = () => history.push(ROUTES.patientsDetails);

    const handleRefetch = () => {
        refetchPatients();
    };

    const performAddPatient = () => {
        setAddPatientLoading(true);
        setLoackInput(true);
        MailJetableService.getMailJetable().then((response) => {
            let testEmail = payload?.email;
            if (testEmail) {
                let afterArobase = testEmail.split('@')[1];
                let mailJetableArray: any[] = [];
                let responses = response?.data?.data;
                responses.map((element: any) => mailJetableArray.push(element.name.toLowerCase()));
                if (mailJetableArray.includes(afterArobase.toLowerCase())) {
                    setAddPatientLoading(false);
                    setLoackInput(false);
                    toast.error("Votre adresse email n'est pas une adresse professionnelle");
                } else {
                    PatientService.postPatient({ ...payload })
                        .then((response) => {
                            if (response.data.code === 200) {
                                setActiveLoadingIndicator(true);
                                handleRefetch();
                                setPayload(defaultPatientPayload);
                                toast.success(OperationMessage.success);
                                const newPatient = response?.data?.data;
                                setUpdate?.((prev: boolean) => !prev);

                                if (newPatient?.id > 0) {
                                    setJustAddedPatient(newPatient);
                                    history.push(`/patients/list/${newPatient?.id}/profile`);
                                }
                            } else if (response.data.code === 400) {
                                // setPostTraitementMessage(message.patientAlreadyExist);
                                toast.error(message.patientAlreadyExist);
                                setPayload(payload);
                            }
                            setAddPatientLoading(false);
                            setLoackInput(false);
                        })
                        .catch((error) => {
                            // setPostTraitementMessage(message.patientNotInserted);
                            toast.error(message.patientNotInserted);
                            setAddPatientLoading(false);
                            setLoackInput(false);
                        });
                }
            }
        });
    };

    const performGetPatient = (idPatient: any) => {
        PatientService.getPatient(idPatient)
            .then((res) => {
                const result = res?.data?.data;
                setSelectedPatient((prev: any) => {
                    return result;
                });
            })
            .catch(() => {});
    };

    const performPutPatient = () => {
        setAddPatientLoading(true);
        setLoackInput(true);
        PatientService.putPatient({ ...payload })
            .then((response) => {
                if (response.data.code === 200) {
                    handleRefetch();
                    performGetPatient(payload?.id);
                    setSelectedPatient((prev: Patient) => {
                        const newProfList = prev?.professionals?.map((professionnal) => {
                            if (professionnal.id === medecinTraitantId) {
                                return {
                                    ...professionnal,
                                    isMedecinTraitant: true,
                                };
                            }
                            return {
                                ...professionnal,
                                isMedecinTraitant: false,
                            };
                        });

                        return {
                            ...prev,
                            ...payload,
                            professionals: newProfList ?? [],
                        };
                    });
                    // setPostTraitementMessage(message.patientModifSuccessfully);
                    toast.success(message.patientModifSuccessfully);
                    setUpdateList((prev: any) => !prev);
                    history.push(`/patients/list/${payload?.id}/profile`);
                } else if (response.data.code === 400) {
                    // setPostTraitementMessage(message.patientAlreadyExist);
                    toast.error(message.patientAlreadyExist);
                }
                setAddPatientLoading(false);
                setLoackInput(false);
            })
            .catch((error) => {
                // setPostTraitementMessage(message.patientNotInserted);
                toast.error(message.patientNotInserted);
                setAddPatientLoading(false);
                setLoackInput(false);
            });
    };

    return (
        <Grid item>
            <Grid container direction="row" justifyContent="center" style={{ marginTop: 10, marginBottom: 40 }}>
                <Grid container direction="row" justifyContent={'space-evenly'}>
                    <CustomLoadingButton
                        orange
                        style={{ marginBottom: 5 }}
                        label="ANNULER"
                        handleClick={backProfil}
                        width="45%"
                    />

                    <CustomLoadingButton
                        style={{ marginBottom: 5 }}
                        loading={addPatientLoading}
                        disabled={!validPatientData()}
                        label={payload?.id ? 'Enregistrer les modifications' : "Valider l'inscription"}
                        handleClick={() => (payload?.id ? performPutPatient() : performAddPatient())}
                        width="45%"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
