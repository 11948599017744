import { Box, Grid, Typography } from '@mui/material';
import { FC, useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import { CustomButton } from 'src/components/Button/CustomButton';
import { CustomRadio } from 'src/components/InputField/CustomRadio';
import { InputField } from 'src/components/InputField/InputField';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { ROUTES } from 'src/routes/routes';
import { isValidMail } from 'src/utils/helpers/InputValidator';
import { forgotPassword } from 'src/services/Login';
import IvehteCptsLogo from 'src/components/IvehteCptsLogo/IvehteCptsLogo';
import { useHistory } from 'react-router';
import { UserContext } from 'src/providers/UserProvider';

export const PasswordForgot: FC = (props) => {
    const [mail, setMail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { setResetPasswordEmail } = useContext(UserContext);
    const history = useHistory();
    const performSendMail = () => {
        setLoading(true);
        setError(false);
        forgotPassword(mail)
            .then((response) => {
                if (response?.data?.code !== 200) {
                    setLoading(false);
                    setError(true);
                } else {
                    setLoading(false);
                    setError(false);
                    setMail('');
                    history.push('/mailVerification');
                }
            })
            .catch((e) => setLoading(false));
    };

    return (
        <Grid>
            <Link to={ROUTES.login}>
                <Box style={{ padding: 10 }}>
                    <IvehteCptsLogo width={80} height={80} />
                </Box>
            </Link>

            <Grid
                container
                justifyContent={'center'}
                justifyItems={'center'}
                alignItems={'center'}
                alignContent={'center'}
            >
                <Grid
                    item
                    sm={8}
                    xs={8}
                    md={4}
                    lg={4}
                    style={{
                        borderRadius: 10,
                        padding: 20,
                        background: baseColors.green.light,
                    }}
                >
                    <CustomText
                        style={{ textAlign: 'center', marginBottom: 20, marginTop: 10 }}
                        fontFamily={baseFontFamily.OpenSansBold}
                        fontSize={18}
                        color={baseColors.green.primary}
                    >
                        Réinitialiser son mot de passe
                    </CustomText>
                    <Grid container direction={'row'}>
                        <CustomRadio
                            style={{ marginBottom: 20 }}
                            checked
                            fontFamily={baseFontFamily.OpenSansBold}
                            color={baseColors.orange.primary}
                            label="Renseigner l'adresse email associée à votre compte"
                        />
                        <CustomRadio
                            style={{ marginBottom: 20 }}
                            checked
                            fontFamily={baseFontFamily.OpenSansBold}
                            color={baseColors.orange.primary}
                            label="Vous recevrez un email à cette adresse. Cliquez sur le lien pour choisir un nouveau mot de passe"
                        />
                        <CustomRadio
                            style={{ marginBottom: 20 }}
                            checked
                            fontFamily={baseFontFamily.OpenSansBold}
                            color={baseColors.orange.primary}
                            label="Vous pouvez maintenant vous connecter à votre espace IVEHTE"
                        />
                        <Grid
                            item
                            flexGrow={1}
                            justifyContent={'center'}
                            style={{ paddingLeft: '3%', paddingTop: '5%' }}
                        >
                            <InputField
                                width={'97%'}
                                validator={isValidMail}
                                errorMessage="Adresse email non valide"
                                placeholder="Entrer votre adresse email"
                                label="Adresse email :"
                                value={mail}
                                disabled={loading}
                                onChange={(value: any) => {
                                    setMail(value);
                                    setResetPasswordEmail(value);
                                    setError(false);
                                }}
                            />
                            {error && (
                                <CustomText
                                    fontFamily={baseFontFamily.BreeSerif}
                                    style={{ marginTop: 20, textAlign: 'center' }}
                                    color={baseColors.orange.primary}
                                >
                                    Ce compte n'existe pas !
                                </CustomText>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        direction={'row'}
                        container
                        justifyContent={'center'}
                        style={{ marginTop: 20, marginBottom: 10 }}
                    >
                        <CustomButton
                            width={'60%'}
                            lower
                            disabled={!isValidMail(mail)}
                            loading={loading}
                            onClick={() => performSendMail()}
                            label="Réinitialiser le mot de passe"
                        ></CustomButton>
                    </Grid>
                    <Link to={ROUTES.login} style={{ textDecoration: 'none' }}>
                        <Typography
                            style={{ textAlign: 'center', fontSize: '16px', margin: 20, textDecoration: 'underline' }}
                            color={baseColors.green.primary}
                        >
                            Se connecter
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
};
