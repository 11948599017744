import { CreateObservationPayload } from 'src/interfaces/interfaces';
import axios from './AxiosInstance';

const getObservationStartPrograms = (patientId: number) =>
    axios.get(`api/observationStartPrograms?patientId=${patientId}`);

const GetObservationRemark = ({ patientId, axisId }: { patientId: number; axisId: number }) =>
    axios.get(`api/observationStartPrograms?patientId=${patientId}&axisId=${axisId}`);

const postObservationStartPrograms = (payload: CreateObservationPayload) =>
    axios.post('api/observationStartPrograms', payload);

export { getObservationStartPrograms, GetObservationRemark, postObservationStartPrograms };
