import {
    DefaultObservationPayload,
    GetSpecificUserAxis,
    Report,
    UpdatePatientGoalPayload,
} from 'src/interfaces/interfaces';
import axios from './AxiosInstance';
import { ConclusionPayloadType } from 'src/interfaces/types';

export interface AcceptedPatient {
    professionalId: number;
    searchQuery?: string;
    dateFilter?: string;
}

const getPatients = (filters?: any) => {
    let additionalUrl: string = '';
    if (filters?.searchQuery) {
        additionalUrl += '&keywords=' + filters.searchQuery;
    }
    if (filters?.dateFilter) {
        additionalUrl += '&birthDate=' + filters.dateFilter;
    }

    return axios.get('api/patients?paginated=false' + additionalUrl);
};

const getFakePictures = (count: number) => axios.get(`https://randomuser.me/api/?results=${count}&inc=picture`);

const searchPatientByName = (name: any) => axios.get(`api/patients?paginated=false&name=${name}`);

const deleteObservations = (id: number) => axios.delete(`api/observations/${id}`);

const getPatient = (id: any) => axios.get('api/users/' + id);

const postPatient = (data: any) => axios.post('api/users', { ...data, category: 'PATIENT' });

const putPatient = (data: any) => axios.put('api/users/' + data.id, { ...data, category: 'PATIENT' });

const postProfessionals = (data: any) => axios.post('api/users', { ...data, address: '', category: 'PROFESSIONAL' });

const postReports = (data: Report) => axios.post('api/reports', { ...data });

const getReports = (userId: number) => axios.get(`api/reports/?patientId=${userId}?paginated=false`);

const getReportsPdf = (reportId: number) => axios.get(`api/reports/${reportId}/export`);

const postConclusions = (payload: any) => axios.post('api/conclusionsTemp', { ...payload });

const putConclusions = ({ payload, conclusionTempId }: { payload: ConclusionPayloadType; conclusionTempId: number }) =>
    axios.put(`api/conclusionsTemp/${conclusionTempId}`, { ...payload });

const getConclusions = ({ patientId, isDraft }: { patientId: number; isDraft?: number }) => {
    let additionalUrl = '';

    if (isDraft !== undefined) {
        additionalUrl += `&isDraft=${isDraft}`;
    }

    return axios.get(`api/conclusionsTemp?paginated=false&patientId=${patientId + additionalUrl}`);
};

const getStatePatientProgram = (id: number) => axios.get(`/api/patients/${id}/hasCurrentProgram`);

const getinvitationByProfessional = (userId: number) =>
    axios.get(`api/healthProfessionals?professionalId=${userId}&isAcceptedByProfessional=new&canBeCoactor=1`);

const getAcceptedByProfessional = (filters: AcceptedPatient) => {
    let additionalUrl: string = '';

    if (filters?.searchQuery) {
        additionalUrl += '&patientKeywords=' + filters.searchQuery;
    }
    if (filters?.dateFilter) {
        additionalUrl += '&patientBirthDate=' + filters.dateFilter;
    }
    if (filters?.professionalId) {
        additionalUrl += '&professionalId=' + filters.professionalId;
    }

    return axios.get(`api/healthProfessionals?paginated=false${additionalUrl}`);
};

const getObsrvationById = (id: number) => axios.get(`api/observations/${id}`);

const postAgreementByProfessional = (payload: any) => axios.post('api/agreements/respondCoactor', { ...payload });

const updateProgramAxe = (id: number, data: any) => axios.put(`api/CaretoolDiageduAxis/${id}`, { ...data });

const postCareAxis = (data: any) => axios.post('api/tabAxeSoinAnswer', { ...data });

const getCareAxis = (userId: number) => axios.get(`api/tabAxeSoinAnswer?userId=${userId}`);

const putCareAxis = (id: number, data: any) => axios.put(`api/tabAxeSoinAnswer/${id}`, { ...data });

const putSoignant = ({ userId, payload }: { userId: number; payload: any }) =>
    axios.put(`api/users/${userId}`, { ...payload });

const postAssistants = (data: any) => axios.post('api/users', { ...data, address: 'france', category: 'ASSISTANT' });

const sendMail = (data: { subject: string; content: string; recipients: Array<any> }) =>
    axios.post('api/users/sendMail', { ...data });

const getProfessionals = (searchKey?: string) => {
    let additionalUrl: string = '';

    if (searchKey) {
        additionalUrl += `?keywords=${searchKey}`;
    }

    return axios.get('api/professionals' + additionalUrl);
};

const getProfessionalObsevations = (searchKey?: string, patientId?: number, programId?: number) => {
    let additionalUrl: string = '';

    if (searchKey) {
        additionalUrl += `&keywords=${searchKey}`;
    }

    if(programId) additionalUrl += `&programId=${programId}`;

    return axios.get(`api/healthProfessionals?patientId=${patientId}&isAcceptedByPatient=1` + additionalUrl);
};

const getProfessionalsIsEtp = (searchKey?: any) => {
    let additionalUrl: string = '';

    /* if (searchKey) {
        additionalUrl += `?keywords=${searchKey}`;
    } */

    return axios.get('api/professionals?role=ROLE_DOCTOR&details=false' + additionalUrl);
};

const getProfessionalsProg = (searchKey?: any) => {
    let additionalUrl: string = '';

    /* if (searchKey) {
        additionalUrl += `?keywords=${searchKey}`;
    } */

    return axios.get('api/professionals?role=ROLE_DOCTOR&details=false&isExternal=0' + additionalUrl);
};

const getProfessionalsIsEtps = (searchKey?: any) => {
    let additionalUrl: string = '';

    if (searchKey) {
        additionalUrl += `?role=${searchKey}`;
    }

    return axios.get('api/professionals' + additionalUrl + '&details=true');
};

const getProfessionalsAcceptedETP = () => axios.get('api/professionals?hasEtp=1');

const getProfessionalsRefusedETP = () => axios.get('api/professionals?hasEtp=0');

const getAssistants = (searchKey?: string, patientId?: number) => {
    let additionalUrl: string = '';
    if (searchKey) {
        additionalUrl += `?keywords=${searchKey}`;
    }
    if (patientId) {
        additionalUrl += `?patientId=${patientId}`;
    }

    return axios.get('api/assistants' + additionalUrl);
};

const getUserLastLogin = (userId: number) => axios.get(`api/patients/lastlogin/${userId}`);

const getProgramList = (userId: number) => axios.get(`api/programs/patients/${userId}`);

const getProgramListPatient = () => axios.get(`api/programs`);

const getAntecedentsList = (id: number) => axios.get(`api/antecedents?patientId=${id}`);

const getAssistantList = (id: number) => axios.get(`api/assistants?patientId=${id}`);

const getPhysicalActivity = (id: number) => axios.get(`api/users/${id}/physicalActivityLevels`);

const getPatientObservations = (id: number) => axios.get(`api/patients/${id}/observations`);

const getConstantVitalSignsList = (userId: number) =>
    axios.get(`api/users/${userId}/constantVitalSigns?paginated=false&archived=0`);

const addPatientsObservation = (data: any) => axios.post(`api/observations`, { ...data });

const updatePatientsObservation = (id: number, data: any) => axios.put(`api/observations/${id}`, { ...data });

const getTreatments = (id: number) => axios.get(`api/users/${id}/treatments`);

const getObservationType = () => axios.get(`api/observationTypes`);

const getAxis = () => axios.get(`api/axis?paginated=false`);

const getSpecificUserAxis = ({ axisId, userId, DEId }: GetSpecificUserAxis) => {
    let additionalUrl = '';

    if (DEId) {
        additionalUrl += `&educationalDiagnosisId=${DEId}`;
    }

    return axios.get(`api/userSurveys/axes/${axisId}/users/${userId}?paginated=false${additionalUrl}`);
};

const getChartDistanceDetails = (userId: number) => axios.get(`api/patients/${userId}/detailsDistance`);

const getLatestProgramPdf = (userId: number, programId: number) =>
    axios.get(`api/programs/latest/export?patientId=${userId}&programId=${programId}`);

const getChartDurationDetails = (userId: number) => axios.get(`api/patients/${userId}/detailsDuration`);

const getGoalPatients = (id: number) => axios.get(`api/goals?patientId=${id}`);

const getQuizes = (patientId: number) => axios.get(`/api/userQuizz/${patientId}`);

const getDetailsPatientForum = (id: number) => axios.get(`api/patients/${id}/detailsParticipationForum`);

const getVideo = (id: number) => axios.get(`/api/users/${id}/video`);

const getGoalStatistics = (filters: { patientId: number; axisId: number }) =>
    axios.get(`api/goalStatistics?patientId=${filters.patientId}`);

const getGoalDoneDetails = (filters: { patientId: number; startDate?: string; endDate?: string }) =>
    axios.get(`api/goalDoneDetails?patientId=${filters.patientId}`);

const getResponseStatistics = (patientId: number) => axios.get(`api/quizz/responseStatistics/${patientId}`);

const getCareTools = () => axios.get(`api/careTools?paginated=false`);

const getDefaultProgram = (id: number) => axios.get(`api/users/${id}/CaretoolDiageduAxis`);

const getChronicPathologies = (patientId: number) => axios.get(`api/patients/${patientId}/chronicPathologies`);

const addProfilePictureSoignant = (data: any) => axios.post(`api/ImageProfil`, { ...data });

const getProfilePicture = (userId: number) => axios.get(`api/users/${userId}/ImageProfil?paginated=false`);

const putProfilePicture = (data: any) => axios.put('/api/ImageProfil/' + data.id, { ...data });

const putChangePassword = (id: number, data: any) => axios.put(`api/users/changePassword/${id}`, { ...data });

const sendMedMail = (data: { subject: string; content: string; recipientId: number }) =>
    axios.post('api/medimails/mss', { ...data });

const getPatientNextRdv = (userId: number) => axios.get(`api/patients/${userId}/nextRdv`);

const postProgramAxe = (payload: any) => axios.post('api/CaretoolDiageduAxis', { ...payload });

const getProgramAxe = () => axios.get('api/CaretoolDiageduAxis');

const getPatientProgram = ({ patientId }: { patientId: number }) =>
    axios.get(`api/programSelected/users?userId=${patientId}`);

const postDEObservationByAxis = (payload: DefaultObservationPayload) =>
    axios.post('api/userSurveys/valueTrame', { ...payload });

const getSurveyByAxis = (axisId: number) => axios.get(`api/surveys?paginated=false&axisId=${axisId}`);

const UpdatePatientGoalStatus = (goalId: number, payload: UpdatePatientGoalPayload) =>
    axios.put(`api/goals/${goalId}`, { ...payload });
    
const exportUserSurveyResponse = () => axios.get(`/api/exportUserSurveyToCsv`)

export const PatientService = {
    UpdatePatientGoalStatus,
    getSurveyByAxis,
    putConclusions,
    postDEObservationByAxis,
    getPatientProgram,
    getPatientNextRdv,
    getProfessionalsAcceptedETP,
    getProfessionalsRefusedETP,
    getChronicPathologies,
    getAcceptedByProfessional,
    getCareTools,
    getProfessionalsIsEtps,
    getPatients,
    getPatient,
    postPatient,
    putPatient,
    putSoignant,
    postProfessionals,
    postAssistants,
    sendMail,
    getProfessionalObsevations,
    getProfessionals,
    getAssistants,
    getUserLastLogin,
    getProfessionalsProg,
    getProgramList,
    searchPatientByName,
    getFakePictures,
    getAntecedentsList,
    getPhysicalActivity,
    getPatientObservations,
    getConstantVitalSignsList,
    addPatientsObservation,
    updatePatientsObservation,
    getTreatments,
    getObservationType,
    getVideo,
    getGoalPatients,
    getAxis,
    getSpecificUserAxis,
    getChartDistanceDetails,
    getChartDurationDetails,
    getDetailsPatientForum,
    getQuizes,
    getGoalStatistics,
    getGoalDoneDetails,
    getResponseStatistics,
    addProfilePictureSoignant,
    getProfilePicture,
    putProfilePicture,
    postReports,
    getReports,
    postConclusions,
    getConclusions,
    getReportsPdf,
    postCareAxis,
    getAssistantList,
    getCareAxis,
    putCareAxis,
    getProgramListPatient,
    getDefaultProgram,
    updateProgramAxe,
    getinvitationByProfessional,
    postAgreementByProfessional,
    sendMedMail,
    deleteObservations,
    putChangePassword,
    getStatePatientProgram,
    getObsrvationById,
    getLatestProgramPdf,
    getProgramAxe,
    postProgramAxe,
    getProfessionalsIsEtp,
};

export {
    getProfessionalsAcceptedETP,
    getProfessionalsRefusedETP,
    getPatients,
    getAcceptedByProfessional,
    getProfessionalsProg,
    getPatient,
    postPatient,
    putPatient,
    putSoignant,
    postProfessionals,
    postAssistants,
    sendMail,
    getProfessionals,
    getProfessionalsIsEtps,
    getAssistants,
    getUserLastLogin,
    getProgramList,
    searchPatientByName,
    getFakePictures,
    getAntecedentsList,
    getPhysicalActivity,
    getPatientObservations,
    getConstantVitalSignsList,
    addPatientsObservation,
    updatePatientsObservation,
    getTreatments,
    getObservationType,
    getVideo,
    getAxis,
    getSpecificUserAxis,
    getChartDistanceDetails,
    getChartDurationDetails,
    getDetailsPatientForum,
    getGoalStatistics,
    getGoalDoneDetails,
    getResponseStatistics,
    addProfilePictureSoignant,
    getProfilePicture,
    putProfilePicture,
    postReports,
    getReports,
    postConclusions,
    getConclusions,
    getReportsPdf,
    postCareAxis,
    getAssistantList,
    getCareAxis,
    putCareAxis,
    getProgramListPatient,
    getDefaultProgram,
    updateProgramAxe,
    getinvitationByProfessional,
    postAgreementByProfessional,
    sendMedMail,
    getLatestProgramPdf,
    getProfessionalObsevations,
    deleteObservations,
    getStatePatientProgram,
    getObsrvationById,
    getProgramAxe,
    postProgramAxe,
    exportUserSurveyResponse
};
