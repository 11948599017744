import { Divider, Grid, LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { FC } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import CheckIcon from '../CustomIcon/CheckIcon';
import { CustomText } from '../Text/CustomText';

interface PatientGoalCardProps {
    state?: string;
    title?: string;
    progress?: number;
    defaultActivePatient?: number;
    data?: any;
}

export const PatientGoalCard: FC<PatientGoalCardProps> = (props) => {
    const { title, state, progress, data } = props;

    const pourcentageCalcul = () => {
        const resultValue = (data?.numValidated / data?.numTimeValidation) * 100;

        return resultValue.toFixed(0);
    };

    const finished = state && state === 'Terminé' ? true : false;
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 5,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: finished ? baseColors.green.primary : baseColors.orange.primary,
        },
    }));

    const isOneTime = data?.numTimeValidation === 1;
    const numValidated = data?.numValidated;

    const renderOneTimeContent = () => {
        const value = () => {
            switch (numValidated) {
                case 5:
                    return 'Atteint';

                case 4:
                    return 'Quasi atteint';

                case 3:
                    return 'Partiellement atteint';

                case 2:
                    return 'En cours';

                default:
                    return 'Non atteint';
            }
        };

        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                <div
                    style={{
                        height: 20,
                        width: 20,
                        borderRadius: 10,
                        backgroundColor: handleSetColor(numValidated),
                        marginRight: 10,
                    }}
                />
                <CustomText
                    style={{ textTransform: 'uppercase', marginTop: 5, marginBottom: 5, textAlign: 'center' }}
                    fontSize={12}
                >
                    {value()}
                </CustomText>
            </div>
        );
    };

    return (
        <Box
            style={{
                width: '100%',
                background: finished ? baseColors.green.light : baseColors.orange.light,
                padding: 10,
                marginBottom: 10,
                borderRadius: 10,
            }}
        >
            <CustomText
                style={{ justifyContent: isOneTime ? 'center' : undefined }}
                fontSize={12}
                fontFamily={baseFontFamily.OpenSansSemiBold}
            >
                {title ? title : `${data?.name}`}
            </CustomText>

            {isOneTime ? (
                renderOneTimeContent()
            ) : (
                <>
                    <Grid direction={'row'} container justifyContent={'space-between'}>
                        <CustomText style={{ marginTop: 5, marginBottom: 5 }} fontSize={12}>
                            {`${progress ? progress : pourcentageCalcul()}% ${data?.state.name}`}
                        </CustomText>
                        {finished && <CheckIcon />}
                    </Grid>
                    <BorderLinearProgress variant="determinate" value={progress ? progress : +pourcentageCalcul()} />
                </>
            )}

            <Divider />
        </Box>
    );
};

export function handleSetColor(slider: number) {
    switch (slider) {
        case 5:
            return 'green';

        case 4:
            return 'yellow';

        case 3:
            return 'orange';

        case 2:
            return 'red';

        default:
            return 'purple';
    }
}

export function OneTimeGoalLabel(numValidated: number) {
    switch (numValidated) {
        case 5:
            return 'Atteint';
        case 2:
            return '2/5 seulement atteint';
        case 3:
            return 'Partiellement atteint';
        case 4:
            return '4/5 atteint';

        default:
            return 'Non atteint';
    }
}
