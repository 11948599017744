import { ButtonBase } from '@mui/material';
import { FC } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import SvgBrain from '../CustomIcon/SvgBrain';
import SvgLegume from '../CustomIcon/SvgLegume';
import SvgPhysics from '../CustomIcon/SvgPhysics';
import SvgTools from '../CustomIcon/SvgTools';
import { CustomText } from '../Text/CustomText';
import useStyles from './styles';
import moment from 'moment';

interface ObservationItemProps {
    type?: string;
    title?: string;
    onPress?: Function;
    date?: any;
}

export const ObservationItem: FC<ObservationItemProps> = (props) => {
    const { type, title, onPress, date } = props;

    const getIcon = () => {
        switch (type) {
            case 'APA':
                return <SvgPhysics />;
            case 'Diététique':
                return <SvgLegume />;
            case 'Médical':
                return <SvgTools />;
            case 'Psychologique':
                return <SvgBrain />;
            default:
                return <SvgPhysics />;
        }
    };

    const getColor = () => {
        switch (type) {
            case 'APA':
                return baseColors.orange.primary;
            case 'Diététique':
                return baseColors.green.pastel;
            case 'Médical':
                return baseColors.orange.dark;
            case 'Psychologique':
                return baseColors.green.dark;
            default:
                return baseColors.orange.primary;
        }
    };

    return (
        <ButtonBase
            style={{
                width: '98%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                background: getColor(),
                paddingTop: 15,
                paddingBottom: 15,
                overflow: 'hidden',
                borderRadius: 8,
                marginBottom: 8,
                alignItems: 'center',
            }}
            onClick={() => {
                onPress && onPress();
            }}
        >
            <div style={{ paddingLeft: 20 }}>
                <CustomText color={baseColors.white} fontFamily={baseFontFamily.OpenSansSemiBold}>
                    {title}
                </CustomText>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                    alignItems: 'center',
                    paddingRight: type !== 'APA' ? 8 : 0,
                }}
            >
                <CustomText
                    style={{ marginRight: type !== 'APA' ? 50 : 58 }}
                    color={baseColors.white}
                    fontFamily={baseFontFamily.BreeSerif}
                >
                    {type}
                </CustomText>
                <div style={{ marginRight: 20 }}>
                    <CustomText
                        style={{ marginRight: type !== 'APA' ? 50 : 58 }}
                        color={baseColors.white}
                        fontFamily={baseFontFamily.OpenSansRegular}
                    >
                        {moment(date).format('DD/MM/YYYY')}
                    </CustomText>
                </div>
                <div
                    style={{
                        background: 'rgba(255,255,255,0.35)',
                        width: 105,
                        height: 90,
                        borderRadius: 45,
                        right: -25,
                        position: 'absolute',
                    }}
                ></div>
                {type ? getIcon() : <SvgPhysics />}
            </div>
        </ButtonBase>
    );
};
