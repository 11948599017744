import { FC } from 'react';
import { RotatingLines, ThreeDots } from 'react-loader-spinner';
import { baseColors } from 'src/utils/constants/baseStyles';
import ivehteLogo from '../../assets/icons/Logo2.png';
import { makeStyles } from '@mui/styles';

interface CustomCircleLoaderProps {
    height?: number;
    width?: number;
    userInfos?: boolean;
    color?: string;
}

const CustomCircleLoader: FC<CustomCircleLoaderProps> = (props) => {
    const { height, width, userInfos, color } = props;

    if (userInfos) {
        return <ThreeDots color={color ?? baseColors.green.primary} height={height ?? 100} width={width ?? 100} />;
    }
    
    /*return (
        <RotatingLines
            // strokeheight={`${height ?? 100}`}
            width={`${width ?? 100}`}
            strokeColor={baseColors.green.primary}
            strokeWidth="5"
            animationDuration="0.75"
            visible={true}
        />
    );*/

    return (
        <div style={{position: "relative",width: width ?? "100px",height: width ?? "100px"}}>
            <div style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: width ?? "100px",
                height: width ?? "100px",
                border: "4px solid "+baseColors.green.primary,
                borderTop: "4px solid transparent",
                borderRadius: "50%",
                animation: "spin 1s linear infinite",
            }}></div>
            <img style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: width ?? "100px",
                    height: width ?? "100px",
                    border: "2px solid #fff",
                    borderRadius: "50%",
                }} 
                src={ivehteLogo} alt=""/>
        </div>
    );
};

export default CustomCircleLoader;
