export enum CATEGORIES {
    OTHER = 'OTHER',
    PATIENT = 'PATIENT',
    PROFESSIONAL = 'PROFESSIONAL',
    ASSISTANT = 'ASSISTANT',
}

export enum URER_ROLES {
    ADMIN = 'ROLE_ADMIN',
    COORDINATOR = 'ROLE_COORDINATOR',
    DOCTOR = 'ROLE_DOCTOR',
    PATIENT = 'ROLE_PATIENT',
    USER = 'ROLE_USER',
}

export enum AXIS_CODE {
    APA = 'AXIS_APA',
    DIET = 'AXIS_DIETETIC',
    PSYCHO = 'AXIS_PSYCHOLOGICAL',
    MEDICAL = 'AXIS_MEDICAL',
}

export enum AXIS_IDENTIFICATION {
    APA = 1,
    DIET = 2,
    PSYCHO = 3,
    MEDICAL = 4,
}

export enum PROGRAM_STATUS {
    DRAFT = 1,
    READY = 2,
    CREATING = 3,
    IN_PROGRESS = 4,
    DONE = 5,
}

export enum ATTENDEE_TYPE {
    PARTICIPANTS = 2,
    INTERVENANTS = 2,
}
