import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useEffect } from 'react';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { sxStyles } from '../styles';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import useEducationalDiagnosis from 'src/hooks/useEducationalDiagnosis';
import { Spacer } from 'src/components/Spacer/Spacer';
import useWorkshops from 'src/hooks/useWorkshops';
import useArticleHooks from 'src/hooks/useArticleHooks';
import { ArticlePayload, PostGoalPayload, Workshop, WorkshopItem } from 'src/interfaces/interfaces';
import { knowledges } from 'src/utils/constants/constants';
import { intersectArray, searchElementByName, sortByIds } from 'src/utils/utils';
import useSkillHooks from 'src/hooks/useSkillHooks';
import { CustomCheckBox } from 'src/components/InputField/CustomCheckBox';
import { FilterByAxis, handleClickKnowledge } from '../Goals.utils';
import LinkIcon from '@mui/icons-material/Link';
import Tooltip from '@mui/material/Tooltip';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { WorkshopForm, defaultWorkshopValue } from 'src/views/WorkshopForm/WorkshopForm/WorkshopForm';
import { ArticleForm } from '../../ArticleSettings/Form/ArticleForm';
import { defaultArticlePayload } from '../../ArticleSettings/ArticleLogic';

type IProps = {
    setPayload: Dispatch<SetStateAction<PostGoalPayload>>;
    payload: PostGoalPayload;
    hideDuration: boolean;
    setLoading?: Dispatch<SetStateAction<boolean>>;
};

export const GoalAxis: FC<IProps> = (props) => {
    const { setPayload, payload, hideDuration, setLoading } = props;
    const { slicedAxis: axis } = useEducationalDiagnosis({} as any);
    const { optimizedSkills, fetchingSkills } = useSkillHooks({});
    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);

    const [workshop, setWorkshop] = useState<Workshop>(defaultWorkshopValue);
    const [openWorkshopModal, setOpenWorkshopModal] = useState<boolean>(false);
    const [justAddedWorkshop, setJustAddedWorkshop] = useState<WorkshopItem[]>([]);

    const [article, setArticle] = useState<ArticlePayload>(defaultArticlePayload);
    const [openContentModal, setOpenContentModal] = useState<boolean>(false);
    const [justAddedContent, setJustAddedContent] = useState<WorkshopItem[]>([]);

    const [vidsource, setVidsource] = useState<undefined | string>();
    const [videoLoader, setVideoLoader] = useState<boolean | undefined>();

    const handleSearchSkill = (value: string) => setSearchkey(value);
    useEffect(() => {
        setSearchedItems(searchElementByName(optimizedSkills ?? [], searchkey, 'name'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchkey]);

    const {
        setWorkshopSearchkey,
        finalWorkshopsData,
        handleFetchWorkshops,
        fetchingWorkshops,
        optimizedWorkshops: workshops,
    } = useWorkshops({});

    const { setArticleSearchkey, finalArticlesData, fetchingArticles, handleFetchArticles } = useArticleHooks();

    useEffect(() => {
        // fetchAllAxis();
        handleFetchWorkshops();
        handleFetchArticles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchingWorkshops || fetchingArticles ? setLoading?.(true) : setLoading?.(false);
    }, [fetchingWorkshops, fetchingArticles]);

    useEffect(() => {
        if (justAddedWorkshop.length !== 0) {
            const justAddedWorkshopIds = Array.from(justAddedWorkshop, (item: any) => item.id);
            setPayload((old: any) => ({ ...old, workshopIds: [...payload?.workshopIds, ...justAddedWorkshopIds] }));
        }
    }, [justAddedWorkshop]);

    useEffect(() => {
        if (justAddedContent.length !== 0) {
            const justAddedContentIds = Array.from(justAddedContent, (item: any) => item.id);
            setPayload((old: any) => ({ ...old, articleIds: [...payload?.articleIds, ...justAddedContentIds] }));
        }
    }, [justAddedContent]);

    const handleSearch = (value: string, isWorkshop?: boolean) => {
        if (isWorkshop) {
            setWorkshopSearchkey(value);
        } else {
            setArticleSearchkey(value);
        }
    };
    const toggleWorkshopModal = () => {
        setOpenWorkshopModal(!openWorkshopModal);
    };
    const toggleContentModal = () => {
        setOpenContentModal(!openContentModal);
        setArticle(defaultArticlePayload);
        setVidsource(undefined);
        setVideoLoader(false);
    };

    finalWorkshopsData.forEach((v: any, k: number) => {
        if (v?.hasLinkedRecord) {
            const goal = Array.from(v?.goals, (g: any) => g.name);
            finalWorkshopsData[k].icon = (
                <Tooltip title={`Nom de l'objectif : ${goal?.join(',')}`}>
                    <LinkIcon fontSize="small" sx={{ color: 'rgb(0, 135, 203)', marginLeft: '5px' }} />
                </Tooltip>
            );
        }
    });

    const minHeight = 'calc(90vh - 450px)';
    const showArticles = payload?.knowledgeIds?.includes(1);
    const articleByAxis = sortByIds(FilterByAxis({ data: finalArticlesData, axisId: payload.axisId }));
    const selectedArticles = intersectArray(
        payload?.articleIds,
        Array.from(articleByAxis, (i: any) => i?.id),
    );

    const disableAddWorkshop = payload?.goal.trim() === '' || payload?.axisId === 0;
    const disableAddContent = payload?.goal.trim() === '' || payload?.axisId === 0;

    return (
        <>
            <div style={sxStyles.row}>
                {/* <div style={sxStyles.left}>
                    <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{ width: 80 }}>
                        Axe :
                    </CustomText>
                    {!fetchingAxis ? (
                        <CustomSelect
                            defaultValue={payload.axisId}
                            onChange={(value: any) => setPayload((old: any) => ({ ...old, axisId: value }))}
                            label=""
                            labelKey="label"
                            items={axisData}
                            idSelect="axe"
                            width="100%"
                        />
                    ) : (
                        <CustomCircleLoader height={25} width={25} />
                    )}
                </div> */}

                {/* <div style={sxStyles.right}>
                    {!hideDuration && (
                        <>
                            <CustomText
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                style={{ width: 80 }}
                            >
                                Durée :
                            </CustomText>

                            <CustomSelect
                                defaultValue={payload.duration}
                                onChange={(value: number) => setPayload((old: any) => ({ ...old, duration: value }))}
                                label=""
                                labelKey="duration"
                                idSelect="duration"
                                items={durations}
                                width="100%"
                            />
                        </>
                    )}
                </div> */}
            </div>

            <Spacer />

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{ width: 150 }}>
                    Choisir le savoir :
                </CustomText>

                <div style={sxStyles.knowledgeRow}>
                    {knowledges?.map((knowledge) => (
                        <CustomCheckBox
                            key={knowledge.id}
                            checked={payload.knowledgeIds.includes(knowledge.id)}
                            onClick={handleClickKnowledge({ setPayload, value: knowledge.id })}
                            label={knowledge?.label}
                        />
                    ))}
                </div>
            </div>

            <Spacer />

            <div style={sxStyles.row}>
                {/* <div style={{ ...sxStyles.videos, flex: 0.95 / 3 }}>
                    <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={baseFontSize.mini}>
                        Compétences :
                    </CustomText>

                    <Spacer height={10} />

                    <ListItemFormCheckBox
                        withFilter
                        minHeight={baseScreenHeight.checkBoxItems}
                        selectedRows={new Set(payload?.skillIds)}
                        handleChange={(value: any) =>
                            setPayload((old: any) => ({ ...old, skillIds: Array.from(value) }))
                        }
                        items={dataWithFilter ?? []}
                        loading={fetchingSkills}
                        labelKey="name"
                        handleSearch={(value: string) => handleSearchSkill(value)}
                        maxItems={optimizedSkills?.length}
                        selectedFilterIds={selectedFilterIds}
                        setSelectedFilterIds={setSelectedFilterIds}
                    />
                </div> */}

                <div style={{ ...sxStyles.workshops, flex: (showArticles ? 0.95 : 2) / 2 }}>
                    <div
                        style={{
                            alignItems: 'center',
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'left',
                            gap: '5px',
                        }}
                    >
                        <CustomText
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            style={{}}
                            fontSize={baseFontSize.mini}
                        >
                            Associer un atelier à l'objectif :
                        </CustomText>
                        <CustomLoadingButton
                            style={{ backgroundColor: baseColors.orange.primary }}
                            width={240}
                            label="Ajouter un nouvel atelier"
                            disabled={disableAddWorkshop}
                            handleClick={toggleWorkshopModal}
                        />
                    </div>

                    <Spacer height={10} />

                    <ListItemFormCheckBox
                        minHeight={minHeight}
                        selectedRows={new Set(payload?.workshopIds)}
                        handleChange={(value: any) =>
                            setPayload((old: any) => ({ ...old, workshopIds: Array.from(value) }))
                        }
                        // items={FilterByAxis({ data: finalWorkshopsData, axisId: payload.axisId })}
                        items={finalWorkshopsData}
                        loading={fetchingWorkshops}
                        labelKey="name"
                        handleSearch={(value: string) => handleSearch(value, true)}
                        maxItems={workshops.length}
                    />
                </div>
                {showArticles && (
                    <div style={{ ...sxStyles.videos, flex: 0.95 / 2 }}>
                        <div
                            style={{
                                alignItems: 'center',
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'left',
                                gap: '5px',
                            }}
                        >
                            <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={baseFontSize.mini}>
                                Associer un contenu à l'objectif :
                            </CustomText>
                            <CustomLoadingButton
                                style={{ backgroundColor: baseColors.orange.primary }}
                                width={240}
                                label="Ajouter un nouvel contenu"
                                disabled={disableAddContent}
                                handleClick={toggleContentModal}
                            />
                        </div>
                        <Spacer height={10} />

                        <ListItemFormCheckBox
                            minHeight={minHeight}
                            selectedRows={new Set(selectedArticles)}
                            handleChange={(value: any) =>
                                setPayload((old: any) => ({ ...old, articleIds: Array.from(value) }))
                            }
                            items={articleByAxis}
                            loading={fetchingArticles}
                            labelKey="name"
                            handleSearch={(value: string) => handleSearch(value)}
                            maxItems={workshops.length}
                        />
                    </div>
                )}
            </div>

            <WorkshopForm
                forAdmin
                consultationData={null}
                workshopData={workshop}
                setUpdateList={null}
                modalToggle={setOpenWorkshopModal}
                modalOpen={openWorkshopModal}
                setJustAddedWorkshop={setJustAddedWorkshop}
                callBack={handleFetchWorkshops}
                customTitle={`Créer un nouvel atelier pour l'objectif : ${payload?.goal}`}
            />

            <ArticleForm
                article={article}
                setArticle={setArticle}
                selectedPostData={null}
                vidsource={vidsource}
                setVidsource={setVidsource}
                videoLoader={videoLoader}
                setVideoLoader={setVideoLoader}
                modalOpen={openContentModal}
                toggleModal={setOpenContentModal}
                setJustAddedArticle={setJustAddedContent}
                callBack={handleFetchArticles}
            />
        </>
    );
};
