import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { DefaultObservationPayload, ProposalAnswerSurveys, UserAxisItem } from 'src/interfaces/interfaces';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import useStyles from './styles';
import { InputField } from 'src/components/InputField/InputField';
import AnswerContent from './AnswerContent';
import { handleSetDEObservation, responseTypeValue } from '../Conclusion/Logic';

interface DieteticDEQuestionResponsesProps {
    content: UserAxisItem;
    posting: boolean;
    setPayload: Dispatch<SetStateAction<DefaultObservationPayload>>;
    setPosting: Dispatch<SetStateAction<boolean>>;
    isFirstQuestion?: boolean;
    proposalAnswerSurveys: ProposalAnswerSurveys[];
    groupedResponse: any;

    canValidate: boolean;
}

const DieteticDEQuestionResponses: FC<DieteticDEQuestionResponsesProps> = ({
    posting,
    setPayload,
    content,
    isFirstQuestion,
    proposalAnswerSurveys,
    groupedResponse,
    canValidate,
}) => {
    const classes = useStyles();
    const { survey, response, precision, userSurveyId, valueTrame } = content;
    const [observation, setObservation] = useState(valueTrame);
    const { text, hasPrecision, responseType, hasChild } = survey;
    const { name } = responseType;
    const showPrecision = Boolean(hasPrecision) && precision !== '' && precision !== null;
    const userResponse = proposalAnswerSurveys?.find(
        (proposalAnswer) => proposalAnswer?.proposalAnswer?.id === +response,
    );

    const isFirstElement = groupedResponse?.items?.[0]?.id === content?.id;

    return (
        <div style={{ paddingLeft: !isFirstQuestion ? 20 : 0 }}>
            {hasChild === 1 && (
                <CustomText
                    style={{ textAlign: 'left' }}
                    fontSize={baseFontSize.sm}
                    color={baseColors.orange.primary}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >
                    QUESTION
                </CustomText>
            )}

            {isFirstElement && (
                <CustomText
                    style={{ textAlign: 'left' }}
                    fontSize={baseFontSize.sm}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >
                    {text}
                </CustomText>
            )}

            <Spacer height={10} />

            {hasChild !== 1 && (
                <div style={{ paddingLeft: 20 }}>
                    <AnswerContent
                        label="Réponse"
                        value={responseTypeValue({ name, response: userResponse?.proposalAnswer?.name ?? '' })}
                    />

                    {showPrecision ? <AnswerContent label="Précision" value={precision} /> : null}

                    <Spacer height={5} />

                    <div className={classes.row} style={{ alignItems: 'center', padding: 10 }}>
                        <CustomText
                            style={{ color: baseColors.green.primary, width: 150, marginTop: -20 }}
                            fontSize={12}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                        >
                            OBSERVATION :
                        </CustomText>

                        <InputField
                            multiline
                            label=""
                            placeholder="Taper votre texte"
                            disabled={posting || !canValidate}
                            height={100}
                            width="100%"
                            defaultValue={valueTrame}
                            value={observation}
                            onChange={handleSetDEObservation({ userSurveyId, setPayload, setObservation })}
                        />
                    </div>
                </div>
            )}

            <Spacer />
        </div>
    );
};

export default DieteticDEQuestionResponses;
