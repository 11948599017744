import { Box } from '@mui/material';
import { FC, useContext } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { ProgramPatientWorkshop } from 'src/interfaces/interfaces';
import { ProgramContext } from 'src/providers/ProgramProvider';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface WorkshopItemProps {
    workshopData: ProgramPatientWorkshop;
    handleSetProgram: Function;
}

const WorkshopItem: FC<WorkshopItemProps> = (props) => {
    const { workshopData, handleSetProgram } = props;
    const { name } = workshopData;
    const { checkedWorkshop, setCheckedModule, setCheckedWorkshop,setSelectedWorkshop } = useContext(ProgramContext);
    const handleClick = () => {
        setCheckedWorkshop(workshopData);
        setCheckedModule(null);
        handleSetProgram();
        setSelectedWorkshop(workshopData);
    };

    let selectedModule = false;
     checkedWorkshop?.forEach((element:any) =>{
        if(element?.id === workshopData?.id) selectedModule = true
    });
    //const selectedModule = checkedWorkshop?.id === workshopData?.id;

    return (
        <Box onClick={handleClick} style={{ marginBottom: 10, cursor: 'pointer' }}>
            <CustomText
                fontFamily={selectedModule ? baseFontFamily.OpenSansBold : baseFontFamily.OpenSansSemiBold}
                fontSize={12}
                color={baseColors.black}
            >
                . {name ?? ''}
            </CustomText>
        </Box>
    );
};

export default WorkshopItem;
