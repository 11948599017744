import { Grid } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import { ProgramContext } from 'src/providers/ProgramProvider';
import { Patient, Video } from 'src/interfaces/interfaces';
import { getVideo } from 'src/services/Patients';
import { WorkshopItem } from 'src/interfaces/interfaces';
import { useParams } from 'react-router-dom';
import { getDetailsPatientForum } from 'src/services/Patients';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

interface ProgramWorkshopRowProps {
    patientInfo?: Patient;
}

const ProgramWorkshopRow: FC<ProgramWorkshopRowProps> = (props) => {
    const { checkedModule, checkedWorkshop,loadingPatientProgram,selectedWorkshop,setSelectedWorkshop } = useContext(ProgramContext);
    const { patientInfo } = props;
    const workshops = checkedModule?.workshops ?? [];

    const [videos, setVideos] = useState<any[]>([]);
    const [fetchingVideos, setFetchingVideos] = useState<boolean>(false);
    const videoViewed = () => {
        setFetchingVideos(true);
        getVideo(patientId)
            .then((res) => {
                //console.log(res?.data.data.items);
                const videoData = res?.data.data.items;
                if (videoData) {
                    setVideos(videoData);
                }
                setFetchingVideos(false);
            })
            .catch((err) => {
                console.log('error' + err);
                setFetchingVideos(false);
            });
    };
    useEffect(() => {
        videoViewed();
    }, [patientInfo]);

    useEffect(() => {
        const defaultWorkshop = checkedWorkshop?.[0] ?? [];
        const workshopDate = defaultWorkshop?.date;
        setSelectedWorkshop(defaultWorkshop);
    },[checkedWorkshop])

    const params: any = useParams();
    const patientId = +params?.id;
    const [patientDetailForum, setPatientDetailForum] = useState<any>();

    const fetchPatientDetailForum = async () => {
        await getDetailsPatientForum(patientId)
            .then((response) => {
                const data = response?.data?.data;
                setPatientDetailForum(data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    };

    useEffect(() => {
        if (patientId) {
            fetchPatientDetailForum();
        }
    }, [patientId]);

    const noVideosData = videos?.length === 0;

    return (
        <Grid container direction="row" justifyContent={'space-between'} style={{ paddingLeft: 20, marginTop: 20,gap:"10px" }}>
            <Grid style={{ display: 'flex', flex: 2 / 3, flexDirection: 'column' }} item>
                <CustomText fontSize={16} fontFamily={baseFontFamily.BreeSerif}>
                    Ateliers faits
                </CustomText>
                <Grid container style={{ marginTop: 20, marginLeft: 10 }} direction={'column'}>
                    <Grid item>
                        {loadingPatientProgram ? 
                        (<CustomCircleLoader height={25} width={25} />) 
                        :
                        selectedWorkshop && (
                            <Grid key={selectedWorkshop?.id} container direction={'row'}>
                                <CustomText fontSize={12} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                    {selectedWorkshop?.name} :
                                </CustomText>
                                <CustomText fontSize={14} style={{ marginTop: -2, marginLeft: 10 }}>
                                    {selectedWorkshop?.date ?? 'Non terminé' }
                                </CustomText>
                            </Grid>
                        )}
                        {workshops?.map((workshop: WorkshopItem) => {
                            const { name } = workshop;
                            return (
                                <Grid key={workshop.id} container direction={'row'}>
                                    <CustomText fontSize={12} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                        {name}{' '}
                                    </CustomText>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>

            <Grid style={{ display: 'flex', flex: 1.5 / 3, flexDirection: 'column' }} item>
                <CustomText fontSize={16} fontFamily={baseFontFamily.BreeSerif}>
                    Participation forum
                </CustomText>
                <Grid container style={{ marginTop: 20 }} direction={'column'}>
                    <Grid item>
                        <Grid container direction={'row'}>
                            <CustomText fontSize={14} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                Messages lus :
                            </CustomText>
                            <CustomText fontSize={14} style={{ marginLeft: '8px' }}>
                                {' '}
                                {patientDetailForum?.read}
                            </CustomText>
                        </Grid>
                        <Grid container direction={'row'}>
                            <CustomText fontSize={14} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                Messages postés :
                            </CustomText>
                            <CustomText fontSize={14} style={{ marginLeft: '8px' }}>
                                {' '}
                                {patientDetailForum?.posted}
                            </CustomText>
                        </Grid>
                        <Grid container direction={'row'}>
                            <CustomText fontSize={14} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                Messages répondus :
                            </CustomText>
                            <CustomText fontSize={14} style={{ marginLeft: '8px' }}>
                                {' '}
                                {patientDetailForum?.answered}
                            </CustomText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid style={{ display: 'flex', flex: 1 / 3, flexDirection: 'column' }} item>
                <CustomText fontSize={16} fontFamily={baseFontFamily.BreeSerif}>
                    Vidéos visionnées
                </CustomText>
                <Grid container spacing={1} style={{ marginTop: 20 }} direction={'column'}>
                    {fetchingVideos ? (
                        <CustomCircleLoader height={25} width={25} />
                    ) : noVideosData ? (
                        <CustomText>Aucun vidéos visionnées.</CustomText>
                    ) : (
                        <Grid item style={{    
                            maxHeight: "139px",
                            position: "relative",
                            overflowY: "auto"
                            }}>
                            {videos?.map((item: any) => (
                                <Grid
                                    container
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    gap="11px"
                                    flexWrap="nowrap"
                                >

                                    <video width="150" height="80" controls poster={item?.thumbnailUrl}>
                                        <source src={item?.url}/>
                                    </video>
                                    <CustomText fontSize={11}> {item?.name} </CustomText>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProgramWorkshopRow;
