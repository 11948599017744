import { FC } from 'react';
import CustomModal from 'src/components/CustomModal';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily, baseColors } from 'src/utils/constants/baseStyles';
import { ConclusionPayloadType } from 'src/interfaces/types';
import ConclusionsDetailsCarePlanInformations from './ConclusionsDetailsCarePlanInformations';
import { Divider } from '@mui/material';

interface ConclusionDetailsProps {
    open: boolean;
    setOpen: Function;
    patientId?: number;
    conclusionData: ConclusionPayloadType;
}

export const ConclusionDetails: FC<ConclusionDetailsProps> = (props) => {
    const { open, setOpen, conclusionData } = props;
    const conclusion = [
        {
            label: 'Axe prioritaire de soin',
            value: conclusionData?.priorityAxisCare,
        },
        {
            label: 'Freins',
            value: conclusionData?.freins,
        },
        {
            label: 'Leviers',
            value: conclusionData?.levers,
        },
        {
            label: 'Attentes',
            value: conclusionData?.expectations,
        },
        {
            label: 'Plan de soin',
            value: conclusionData?.carePlan,
        },
        {
            label: 'Evaluation de la motivation au changement',
            value: conclusionData?.motivation,
        },
    ];

    return (
        <CustomModal width="100%" height={'75vh'} size="large" open={open} toggleModal={setOpen}>
            <CustomText
                style={{ textAlign: 'center', color: baseColors.green.primary }}
                fontSize={20}
                fontFamily={baseFontFamily.BreeSerif}
            >
                Détails de la conclusion
            </CustomText>

            <div style={{ height: '100%', marginTop: 30 }}>
                <Spacer height={20} />

                {conclusion?.map((item) => (
                    <div
                        key={item.label}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}
                    >
                        <CustomText
                            style={{ width: 170 }}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            color={baseColors.orange.primary}
                        >
                            {item.label}
                        </CustomText>

                        <CustomText style={{ flex: 1 }}> {item.value || '(Non définie)'}</CustomText>
                    </div>
                ))}

                <Divider style={{ backgroundColor: baseColors.orange.primary, marginTop: 20, marginBottom: 20 }} />

                <ConclusionsDetailsCarePlanInformations conclusion={conclusionData as any} />

                <Spacer height={100} />
            </div>
        </CustomModal>
    );
};
