import { FC, useContext, useEffect, useState } from 'react';
import { CustomCheckBox } from 'src/components/InputField/CustomCheckBox';
import { InputField } from 'src/components/InputField/InputField';
import { NumberSelector } from 'src/components/InputField/NumberSelector';
import { Spacer } from 'src/components/Spacer/Spacer';
import { QuestionWithRadio, renderContent, renderTitle } from '../utils';
import _ from 'lodash';
import useStyles from './styles';
import { ProgramContext } from 'src/providers/ProgramProvider';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { ServerError } from 'src/utils/constants/constants';
import { toast } from 'react-toastify';
import ProgramService from 'src/services/Program/program.service';
interface ProcessProps {
    programId: number;
}

export const Process: FC<ProcessProps> = (props) => {
    const { programId } = props;
    const { setSyntheseHasChange, setsyntheseChangeSaveCallback } = useContext(ProgramContext);
    const defautlProcess = {
        id: 0,
        diagEdu: 0,
        objEdu: 0,
        evalIndividuel: 0,
        implicationPatient: 0,
        conducteurSceance: 0,
        coordExterne: 0,
        coordInterne: 0,
        envoiTraitant: 0,
        envoiSpecialiste: 0,
        envoiAutre: 0,
        programId: programId,
        moyens: '',
    };
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [process, setProcess] = useState(defautlProcess);
    const [oldProcess, setoldProcess] = useState(defautlProcess);

    useEffect(() => {
        const hasChangeMade = !_.isEqual(process, oldProcess);
        setSyntheseHasChange(hasChangeMade);
        if (hasChangeMade) {
            setsyntheseChangeSaveCallback(() => performSaveProgramsProcess);
        }
    }, [process, oldProcess]);

    const getProgramsProcess = () => {
        setLoading(true);
        ProgramService.getProcess(programId)
            .then((response) => {
                let data = response.data?.data;
                if (data) {
                    data.programId = programId;
                    if (data) {
                        setProcess(data);
                        setoldProcess(data);
                    }
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getProgramsProcess();
    }, []);

    const performSaveProgramsProcess = () => {
        return new Promise((resolve, reject) => {
            if (process.id !== 0) {
                //update
                ProgramService.putProcess(process.id, process)
                    .then((response) => {
                        let data = response.data.data;
                        if (data) {
                            data.programId = programId;
                            setProcess(data);
                            setoldProcess(data);
                        }
                        resolve(response);
                    })
                    .catch((e) => {
                        toast.error(ServerError.unknown);
                        reject(e);
                    });
            } else {
                ProgramService.postProcess(process)
                    .then((response) => {
                        let data = response.data.data;
                        if (data) {
                            data.programId = programId;
                            setProcess(data);
                            setoldProcess(data);
                        }
                        resolve(response);
                    })
                    .catch((e) => {
                        toast.error(ServerError.unknown);
                        reject(e);
                    });
            }
        });
    };

    const renderFolderQuality = () => {
        return (
            <div style={{ flex: 3 }}>
                {renderTitle('Qualité du dossier')}
                <Spacer height={20} />
                {renderContent('Le dossier éducatif (papier ou informatisé) contient-il :')}
                <Spacer height={20} />
                <div>
                    <QuestionWithRadio
                        inline
                        radioValue={!!process?.diagEdu}
                        setRadioValue={(value: any) => setProcess((old: any) => ({ ...old, diagEdu: value ? 1 : 0 }))}
                        title=""
                        content="Le diagnositc éducatif"
                    />
                    <QuestionWithRadio
                        inline
                        radioValue={!!process?.objEdu}
                        setRadioValue={(value: any) => setProcess((old: any) => ({ ...old, objEdu: value ? 1 : 0 }))}
                        title=""
                        content="Les objectifs éducatifs"
                    />
                    <QuestionWithRadio
                        inline
                        radioValue={!!process?.evalIndividuel}
                        setRadioValue={(value: any) =>
                            setProcess((old: any) => ({ ...old, evalIndividuel: value ? 1 : 0 }))
                        }
                        title=""
                        content="Les évaluations individuelles"
                    />

                    <div></div>
                </div>
            </div>
        );
    };

    const renderExternalCoordination = () => {
        return (
            <div style={{ flex: 4, marginLeft: 30 }}>
                <div className={classes.rowSimple}>
                    {
                        <QuestionWithRadio
                            title="Coordination externe"
                            content="La synthèse finale de ce dossier est-elle envoyée ?"
                            radioValue={!!process.coordExterne}
                            setRadioValue={(value: any) =>
                                setProcess((old) => ({ ...old, coordExterne: value ? 1 : 0 }))
                            }
                        />
                    }
                    {process.coordExterne > 0 && (
                        <div>
                            <Spacer height={60} />
                            {renderContent('Si oui, à qui est-elle envoyée ?')}
                            <Spacer height={20} />
                            <div>
                                <div>
                                    <CustomCheckBox
                                        onClick={() =>
                                            setProcess((old: any) => ({
                                                ...old,
                                                envoiTraitant: process.envoiTraitant === 0 ? 1 : 0,
                                            }))
                                        }
                                        checked={!!process.envoiTraitant}
                                        label="Médecin traitant généraliste"
                                    />
                                    <CustomCheckBox
                                        onClick={() =>
                                            setProcess((old: any) => ({
                                                ...old,
                                                envoiSpecialiste: process.envoiSpecialiste === 0 ? 1 : 0,
                                            }))
                                        }
                                        checked={!!process.envoiSpecialiste}
                                        label="Médecin spécialiste"
                                    />
                                    <CustomCheckBox
                                        onClick={() =>
                                            setProcess((old: any) => ({
                                                ...old,
                                                envoiAutre: process.envoiAutre === 0 ? 1 : 0,
                                            }))
                                        }
                                        checked={!!process.envoiAutre}
                                        label="Autre"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderPatientImplication = () => {
        return (
            <div style={{ flex: 4 }}>
                <QuestionWithRadio
                    title="Implication patient dans le programme"
                    content="Êtes-vous parvenu à intégrer, solliciter les patients (experts, associations, ressources) pour l'évaluation, la conception, l'animation des séances ?"
                    radioValue={!!process.implicationPatient}
                    setRadioValue={(value: any) => setProcess((old) => ({ ...old, implicationPatient: value ? 1 : 0 }))}
                />
            </div>
        );
    };

    const renderSeanceConductor = () => {
        return (
            <div style={{ flex: 3, marginLeft: 150 }}>
                <QuestionWithRadio
                    title="Conducteur de séance"
                    content=" Existe-t-il un descriptif du déroulé des séances consultable (conducteur de séance) pour chaque séance ?"
                    radioValue={!!process.conducteurSceance}
                    setRadioValue={(value: any) => setProcess((old) => ({ ...old, conducteurSceance: value ? 1 : 0 }))}
                />
            </div>
        );
    };

    const renderInternCoordination = () => {
        return (
            <div style={{ flex: 6, marginLeft: 50 }}>
                {renderTitle('Coordination interne')}
                <Spacer height={20} />
                <div className={classes.rowSimple}>
                    <div>
                        {renderContent('Nombre annuel de réunions physiques dédiées à l’analyse du processus :')}
                        <Spacer height={20} />
                        <div style={{ maxWidth: 80 }}>
                            <NumberSelector
                                value={process.coordInterne}
                                handleChange={(value: any) => setProcess((old) => ({ ...old, coordInterne: value }))}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={classes.container}>
            {loading ? (
                <div className={classes.row} style={{ justifyContent: 'center', marginTop: 40 }}>
                    <CustomCircleLoader />
                </div>
            ) : (
                <div className={classes.wrapper}>
                    <div className={classes.processLine}>
                        {renderFolderQuality()}
                        {renderExternalCoordination()}
                    </div>

                    <Spacer />

                    <div className={classes.processLine}>
                        {renderPatientImplication()}
                        {renderInternCoordination()}
                    </div>

                    <Spacer />

                    <div className={classes.processLine}>
                        <div>
                            {renderTitle('Moyens mis en oeuvre :')}
                            <Spacer height={20} />
                            <InputField
                                width={300}
                                single
                                label=""
                                multiline
                                height={100}
                                value={process.moyens}
                                placeholder="Taper votre texte"
                                onChange={(value: any) => {
                                    setProcess((old) => ({ ...old, moyens: value }));
                                }}
                            />
                        </div>

                        {renderSeanceConductor()}
                    </div>
                </div>
            )}
        </div>
    );
};
