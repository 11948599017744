import moment from 'moment';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { OneTimeGoalLabel, handleSetColor } from 'src/components/CustomCard/PatientGoalCard';
import { OpenSansRegularText } from 'src/components/Text/OpenSansRegularText';
import { baseColors } from 'src/utils/constants/baseStyles';
import GoalColorPopover from './GoalColorPopover/GoalColorPopover';

interface EvaluationGoalItemProps {
    doneProgramGoals?: any[];
    goal: any;
    setUpdating: Dispatch<SetStateAction<boolean>>;
    doneGoalIds?: number[];
    doneProgramIds?: number[];
}

const EvaluationGoalItem: FC<EvaluationGoalItemProps> = (props) => {
    const { doneProgramGoals, goal, setUpdating, doneGoalIds, doneProgramIds } = props;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClickColor = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const updatedAt = moment(goal?.updateddAt).format('DD-MM-YYYY');
    const isOneTime = goal?.numTimeValidation === 1;
    const goalDoneInPreviousPrograms = doneProgramGoals?.find(
        (prevGoal) => prevGoal.id === goal?.id && goal?.state.id === 2,
    ); // 2 = Done
    const isPrevGoalDone = goalDoneInPreviousPrograms?.id! > 0 && doneProgramIds?.includes(goal?.programId);
    const isDoneGoal = doneGoalIds?.includes(goal?.id) ?? false;

    return (
        <>
            <GoalColorPopover goal={goal} setUpdating={setUpdating} setAnchorEl={setAnchorEl} anchorEl={anchorEl} />

            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    padding: 10,
                    backgroundColor: isPrevGoalDone ? baseColors.grey : baseColors.orange.light,
                    borderRadius: 10,
                    marginBottom: 10,
                    cursor: 'pointer',
                    opacity: isPrevGoalDone ? 0.8 : 1,
                    width: '100%',
                    justifyContent: 'space-between',
                }}
                onClick={(e: any) => (isOneTime ? handleClickColor(e) : undefined)}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                    }}
                >
                    <div
                        style={{
                            height: 20,
                            width: 20,
                            borderRadius: 10,
                            backgroundColor: isOneTime ? handleSetColor(goal?.numValidated) : 'transparent',
                            marginRight: 10,
                        }}
                        // onClick={(e: any) => (isOneTime ? handleClickColor(e) : undefined)}
                    />

                    <OpenSansRegularText style={{ textAlign: 'center', fontSize: 14 }}>
                        {goal?.name}
                    </OpenSansRegularText>
                </div>

                <OpenSansRegularText style={{ textAlign: 'end', fontSize: 14, marginTop: 5, marginLeft: 30 }}>
                    <b>
                        {!isDoneGoal
                            ? 'En cours'
                            : isOneTime
                            ? OneTimeGoalLabel(goal?.numValidated)
                            : `${goal?.numValidated}/${goal?.numTimeValidation}`}
                    </b>{' '}
                    dans le programme <b>{goal?.program?.title}</b> <b>{isDoneGoal ? `le ${updatedAt}` : ''}</b>
                </OpenSansRegularText>
            </div>
        </>
    );
};

export default EvaluationGoalItem;
