import { FC } from 'react';
import { Box } from '@mui/material';
import { ProgramFilter } from './Filter/ProgramFilter';
import { ProgramList } from './List/ProgramList';
import { ToastContainer } from 'react-toastify';
import useAdminRedirection from 'src/hooks/useAdminRedirection';

const Programme: FC = () => {
    useAdminRedirection();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <Box flex={2.7} marginRight={1}>
                <ProgramFilter />
            </Box>

            <Box flex={10}>
                <ProgramList />
            </Box>

            <ToastContainer />
        </Box>
    );
};

export default Programme;
