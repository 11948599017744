import { Avatar } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from '../../../utils/constants/baseStyles';
import { useHistory } from 'react-router-dom';
import { formatDateDiag, fullName, remoVeAdditionalTimeZone, ucfirst } from 'src/utils/utils';
import { PatientContext } from 'src/providers/PatientProvider';
import { UserContext } from 'src/providers/UserProvider';
import useCheckUserRole from 'src/hooks/useCheckUserRole';
import useUserHooks from 'src/hooks/useUserHooks';
import { EditProfilIcon } from 'src/components/CustomIcon/EditProfilIcon';
import { ContactProfilIcon } from 'src/components/CustomIcon/ContactProfilIcon';
import { AlertProfilIcon } from 'src/components/CustomIcon/AlertProfilIcon';
import { OpenSansRegularText } from 'src/components/Text/OpenSansRegularText';
import { OpenSansBoldText } from 'src/components/Text/OpenSansBoldText';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CalendarProfilIcon } from 'src/components/CustomIcon/CalendarProfilIcon';
import PatientsDetailsHeaderPrograms from './PatientsDetailsHeaderPrograms/PatientsDetailsHeaderPrograms';
import { useLastSessionRQHooks, usePatientNextRdvRQHooks } from 'src/hooks/ReactQueryHooks/useSessionRQHooks';
import CustomPlayerVisio from 'src/components/CustomVisioIframe/CustomPlayerVisio';
import { VisioContentContext } from 'src/providers/VisioContentProvider';

interface PatientDetailsHeaderProps {
    patientInfo?: any;
    onContact?: Function;
    defaultPatient?: any;
    isActor?: any;
    patientId: number;
    loading: boolean;
}

export const PatientDetailsHeader: FC<PatientDetailsHeaderProps> = (props) => {
    const { onContact } = props;
    const { roles } = useCheckUserRole();
    const history = useHistory();
    const { user: patientInfo, handleFetchUserById } = useUserHooks();
    const { hasEtp, userCoActorStatus } = useContext(UserContext);
    const { setCurrentLocationPathname, setRead, selectedPatient } = useContext(PatientContext);
    const patientId = selectedPatient?.id ?? 0;

    const isCoordinator = roles?.isCoordinator ?? false;
    const isDoctor = roles?.isDoctor ?? false;
    const [isAcceptedByProfessional, setIsAcceptedByProfessional] = useState(false);
    const [isAcceptedByPatient, setIsAcceptedByPatient] = useState(false);

    const { data: lastSessionInfos, isLoading: fetchingSessionInfos } = useLastSessionRQHooks({ patientId });
    const { data: upcomingRdv, isLoading: fetchingRdvInfos } = usePatientNextRdvRQHooks({ patientId });

    const currentDateTime = new Date()?.getTime();
    const upcomingRdvDateTime = new Date(remoVeAdditionalTimeZone(upcomingRdv?.[0]?.startDate))?.getTime();
    const hasRdv = upcomingRdvDateTime > currentDateTime;

    const nextSession = hasRdv
        ? formatDateDiag(remoVeAdditionalTimeZone(upcomingRdv?.[0]?.startDate))
        : 'Pas de prochain RDV';

    const loading = fetchingSessionInfos || fetchingRdvInfos;

    const disabledModf =
        isCoordinator ||
        (isDoctor && hasEtp === true && isAcceptedByProfessional && isAcceptedByPatient) ||
        (isDoctor && patientInfo?.isExternal === 0);

    useEffect(() => {
        handleFetchUserById(patientId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    const editPatient = () => {
        const pathname = `/patients/${patientInfo.id}/edit`;
        setCurrentLocationPathname(pathname);
        history.push(pathname);
    };

    useEffect(() => {
        setIsAcceptedByProfessional(userCoActorStatus.isAcceptedByProfessional === 1);
        setIsAcceptedByPatient(userCoActorStatus?.isAcceptedByPatient === 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientInfo]);

    useEffect(() => {
        // setRead(!disabledModf);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    const lastSessionDate = lastSessionInfos?.lastLogin
        ? formatDateDiag(remoVeAdditionalTimeZone(lastSessionInfos?.lastLogin))
        : 'Patient pas encore connecté';

    return (
        <div
            style={{
                padding: 10,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            {/* {openIframe && <CustomPlayerVisio url={visioContent?.url} />} */}

            {/* Left */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    backgroundColor: baseColors.orange.primary,
                    padding: 20,
                    borderRadius: 10,
                    flex: 2.8 / 4,
                    marginRight: 10,
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        variant="square"
                        alt="patient-avatar"
                        src={selectedPatient?.profilePicture}
                        sx={{ width: 80, height: 80, borderRadius: 1 }}
                    />

                    <div style={{ marginLeft: 20 }}>
                        <CustomText
                            style={{ minWidth: 200, marginRight: 5, marginBottom: 5 }}
                            fontFamily={baseFontFamily.BreeSerif}
                            fontSize={baseFontSize.smTitle}
                            color={baseColors.white}
                        >
                            {fullName(selectedPatient?.firstName!, selectedPatient?.lastName!)}
                        </CustomText>

                        <PatientsDetailsHeaderPrograms />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {
                        /*(selectedPatient?.isCoactor || isCoordinator) && ( */
                        <EditProfilIcon style={{ cursor: 'pointer' }} onClick={() => editPatient?.()} />
                        /*)*/
                    }
                    <ContactProfilIcon style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => onContact?.()} />
                </div>
            </div>

            {/* Right */}
            <div
                style={{
                    display: 'flex',
                    // alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    backgroundColor: '#F6F6F6',
                    padding: 10,
                    borderRadius: 10,
                    flex: 1.5 / 4,
                    marginLeft: 10,
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <AlertProfilIcon />
                    <div
                        style={{
                            display: 'flex',
                            marginLeft: 20,
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <OpenSansRegularText fontSize={14} color="#15254599">
                            Prochain RDV :
                        </OpenSansRegularText>
                        <OpenSansBoldText color="#152545">
                            {loading ? 'Chargement en cours...' : ucfirst(nextSession)}
                        </OpenSansBoldText>
                    </div>
                </div>

                <Spacer height={12} />

                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <CalendarProfilIcon />
                    <div
                        style={{
                            display: 'flex',
                            marginLeft: 20,
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <OpenSansRegularText fontSize={14} color="#15254599">
                            Dernière connexion :
                        </OpenSansRegularText>
                        <OpenSansBoldText color="#152545">
                            {loading ? 'Chargement en cours...' : ucfirst(lastSessionDate)}
                        </OpenSansBoldText>
                    </div>
                </div>
            </div>
        </div>
    );
};
