import { Box } from '@mui/material';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import EventModal from '../EventModal';
import { AgendaContext } from 'src/providers/AgendaProvider';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import { EventPlanningViewType, PlanningAgendaFilters } from 'src/interfaces/interfaces';
import PlanningFilters from './PlanningFilters';
import useAgendaHours from 'src/hooks/useAgendaHours';
import CustomFullCalendar from 'src/components/CustomFullCalendar/CustomFullCalendar';
import { handleFetchEvents } from 'src/components/CustomFullCalendar/event-utils';
import { defaultEventDate } from 'src/components/CustomFullCalendar/calendarUtils';
import useFetchUpstairs from 'src/hooks/useFetchUpstairs';
import { UserContext } from 'src/providers/UserProvider';

interface PlanningProps {
    modalEvent: boolean;
    toggleModal: Function;
    isFromInfos: boolean;
    setIsFromInfos: Dispatch<SetStateAction<boolean>>;
}

const Planning: FC<PlanningProps> = (props) => {
    const { modalEvent, toggleModal, isFromInfos, setIsFromInfos } = props;
    const classes = useStyles();
    const { userInfos } = useContext(UserContext);
    const defaultDate = defaultEventDate();
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [createEventFromBtnAction, setCreateEventFromBtnAction] = useState<boolean>(false);
    const [viewType, setViewType] = useState<EventPlanningViewType['viewType']>('weekly');
    const [caregiverId, setCaregiverId] = useState<number>(0);
    const [specialityId, setSpecialityId] = useState<number>(0);
    const [filterSpecialityId, setFilterSpecialityId] = useState<number>(0);
    const [loadingPlanningEvent, setLoadingPlanningEvent] = useState<boolean>(false);
    const [selectedTime, setSelectedTime] = useState<any>(null);
    const [selectedEventData, setSelectedEventData] = useState<any>(null);
    const [selectedIntervenants, setSelectedIntervenants] = useState<Array<any>>([]);
    const [droppedEventId, setDroppedEventId] = useState<number>(0);
    const [newSpecialityInfos, setNewSpecialityInfos] = useState<any>(null);
    const [newEventDate, setNewEventDate] = useState<string>('');
    const [newEventEndDate, setNewEventEndDate] = useState<string>('');
    const [events, setEvents] = useState([]);
    const [selectedSpecialities, setSelectedSpecialities] = useState([]);
    const [droppedEventDateTime, setDroppedEventDateTime] = useState<{
        start: any;
        end: any;
    }>({
        start: '',
        end: '',
    });
    const { handleFetchHoursData, timeSlot, time } = useAgendaHours();
    const { handleFetchUpstairs } = useFetchUpstairs();

    const {
        currentDate,
        hours,
        eventData,
        duration,
        checkedPrograms,
        checkedEvents,
        checkedRooms,
        checkedLocations,
        yearAndMonth,
    } = useContext(AgendaContext);

    const handleFetchData = async () => {
        const filters: PlanningAgendaFilters = {
            checkedPrograms: checkedPrograms,
            checkedEvents: checkedEvents,
            checkedSpecialitytId: filterSpecialityId,
            checkedRooms: checkedRooms,
            checkedLocations: checkedLocations,
            intervenantsIds: selectedIntervenants,
            durations: duration,
            specialityIds: selectedSpecialities,
            yearAndMonth,
        };

        handleFetchEvents({
            checkedDate: defaultDate,
            filters,
            setFetching: setLoadingPlanningEvent,
            setEvents,
        });
    };

    useEffect(() => {
        handleFetchHoursData();
        handleFetchUpstairs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfos?.id]);

    useEffect(() => {
        handleFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentDate,
        hours,
        caregiverId,
        updateList,
        checkedPrograms,
        checkedEvents,
        checkedRooms,
        checkedLocations,
        filterSpecialityId,
        selectedIntervenants,
        duration,
        yearAndMonth,
        selectedSpecialities,
        defaultDate,
    ]);
    const hasEvents = events?.length > 0;

    return (
        <Box className={classes.container}>
            <PlanningFilters
                hasEvents={hasEvents}
                toggleAddEvnetModal={toggleModal}
                viewType={viewType}
                selectedIntervenants={selectedIntervenants}
                loadingPlanningEvent={loadingPlanningEvent}
                setCaregiverId={setCaregiverId}
                setViewType={setViewType}
                setSelectedSpecialities={setSelectedSpecialities}
                setCreateEventFromBtnAction={setCreateEventFromBtnAction}
                setSelectedIntervenants={setSelectedIntervenants}
            />

            <Box
                style={{
                    overflow: 'hidden',
                    overflowY: 'scroll',
                }}
            >
                {loadingPlanningEvent ? (
                    <div style={{ padding: 20 }}>
                        <CustomLoader isPlanning loadingNumber={4} />
                    </div>
                ) : (
                    <CustomFullCalendar
                        setNewEventDate={setNewEventDate}
                        setNewEventEndDate={setNewEventEndDate}
                        toggleCreateEventModal={toggleModal}
                        events={events}
                        setUpdateList={setUpdateList}
                        setDroppedEventId={setDroppedEventId}
                        setDroppedEventDateTime={setDroppedEventDateTime}
                        timeSlot={timeSlot}
                        timeInterval={time}
                    />
                )}
            </Box>

            <EventModal
                specialityId={specialityId}
                modalOpen={modalEvent}
                modalToggle={toggleModal}
                selectedTime={selectedTime}
                viewType={viewType}
                selectedEventData={selectedEventData}
                createEventFromBtnAction={createEventFromBtnAction}
                droppedEventId={droppedEventId}
                newSpecialityInfos={newSpecialityInfos}
                eventData={isFromInfos ? eventData : undefined}
                edition={isFromInfos ? true : undefined}
                newEventDate={newEventDate}
                newEventEndDate={newEventEndDate}
                setUpdateList={setUpdateList}
                setSpecialityId={setSpecialityId}
                setCreateEventFromBtnAction={setCreateEventFromBtnAction}
                setNewSpecialityInfos={setNewSpecialityInfos}
                setDroppedEventId={setDroppedEventId}
                setIsFromInfos={setIsFromInfos}
                setNewEventDate={setNewEventDate}
                setNewEventEndDate={setNewEventEndDate}
                droppedEventDateTime={droppedEventDateTime}
            />
        </Box>
    );
};

export default Planning;
